import { useContext } from 'react';
import { AuthContext } from '@fifthdomain/fe-shared';
import { useQuery, gql } from '@apollo/client';
import { getUserById } from '../graphql/queries';

const useOrgId = () => {
  const { user } = useContext(AuthContext);
  const { data: userData, loading: userLoading } = useQuery(gql(getUserById), {
    variables: {
      id: user?.username,
    },
    skip: !user,
  });

  return {
    user,
    userData,
    userLoading,
  };
};

export default useOrgId;
