import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, useTheme } from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';
import _ from 'lodash';
import {
  FdChip,
  FdCard,
  FdTypography,
  useQueryRecursive,
  FdCardList,
  FdIconsV5,
  FdSkeleton,
} from '@fifthdomain/fe-shared';
import { Summary } from '../components/Assessment';
import {
  listUsersByAssessmentId,
  getUsersAssessmentTimeSpent,
  listInvitedUsersByAssessmentId,
} from '../graphql/queries';
import {
  getDateTimeZoneFormatted,
  getFormattedDuration,
} from '../shared/utils/dateUtils';
import { getAssessmentStatus } from '../shared/utils/taskUtils';
import { listGroupsUsersByAssessmenId } from '../queries/customQueries';
import { getAssessmentStatusColor } from '../shared/utils/getStatusColor';
import FdSystemDateCountdown from '../components/FdSystemDateCountdown';
import { sortObjectArrayByField } from '../shared/utils/objectUtils';
import TaskCompletionTable from '../components/Overview/TaskCompletionTable';
import { BarNivo, RadarNivo } from '../components/Charts';
import {
  filterBySkill,
  getRankingNormalizedRows,
  getRankingTableRows,
} from '../components/Overview/overviewUtils';
import ComparisonRanking from '../components/Overview/ComparisionRanking';
import TableHeaderColumnWithTooltip from '../components/Assessment/TableHeaderColumnWithTooltip';
import NoDataPresentSvg from '../shared/images/no-data-present.svg';

const Overview = ({
  assessmentId,
  setCustIndex,
  setDefaultInsight,
  assessmentData,
  attemptsData,
  serverTime,
  timeSpentDataForAssessment,
  loading,
  tasksOpened,
}) => {
  const { BarChart, MilitaryTech } = FdIconsV5;
  const theme = useTheme();
  const isDarkTheme = theme?.palette?.type === 'dark';
  const { data: usersData, loading: usersDataLoading } = useQueryRecursive(
    gql(listUsersByAssessmentId),
    {
      variables: {
        userAssessmentAssessmentId: assessmentId,
      },
      limit: 1000,
      staleTime: { seconds: 0 },
    },
  );

  const { data: listInvitedUsersByAssessmentIdData } = useQueryRecursive(
    gql(listInvitedUsersByAssessmentId),
    {
      variables: {
        assessmentId,
      },
      limit: 1000,
      staleTime: { seconds: 0 },
    },
  );

  const { data: groupsAssessmentData } = useQueryRecursive(
    gql(listGroupsUsersByAssessmenId),
    {
      variables: {
        assessmentId,
      },
      staleTime: { seconds: 0 },
      limit: 1000,
    },
  );

  const { data: userAssessmentTimeSpentData } = useQuery(
    gql(getUsersAssessmentTimeSpent),
    {
      variables: {
        assessmentId,
      },
    },
  );

  const timeSpentData =
    userAssessmentTimeSpentData?.getUsersAssessmentTimeSpent || [];

  const { teamBased, startDateTime, endDateTime, hours, minutes, tasks } =
    assessmentData?.getAssessment ?? {};
  const status = getAssessmentStatus(startDateTime, endDateTime, serverTime);
  const statusColor = getAssessmentStatusColor(status);

  const notStarted = status === 'Not Started';
  const participantNotRemovedCount =
    usersData?.listUsersByAssessmentId?.items?.filter(
      (p) => p?.status !== 'REMOVED',
    )?.length || 0;

  const participantInvitedCount =
    listInvitedUsersByAssessmentIdData?.listInvitedUsersByAssessmentId?.items?.filter(
      (participant) => !participant.accepted,
    ).length;

  const participantsCount =
    participantInvitedCount + participantNotRemovedCount;

  const participantsCompleted =
    usersData?.listUsersByAssessmentId?.items.filter(
      (pc) => pc.status === 'FINISHED',
    ).length || 0;
  const participantsStarted =
    usersData?.listUsersByAssessmentId?.items.filter(
      (pc) => pc.status === 'STARTED',
    ).length + participantsCompleted || 0;
  const allTaskAttempts =
    attemptsData?.listTaskAttemptAggregatesByAssessmentId?.items || [];

  const completedTasks = allTaskAttempts?.filter(
    (a) => a.status === 'COMPLETED',
  );

  const allTasksForCompletionTable = _.sortBy(
    tasks?.items?.map(({ task }) => ({
      taskId: task.id,
      taskName: task.name,
      taskDifficulty: task.difficulty,
      taskPoints: task.recommendedPoints,
    })),
    [(t) => t.taskName.toLowerCase()],
  );
  // team based
  const teamGroups = groupsAssessmentData?.listGroupsByAssessmenId?.items || [];
  const teamGroupsCount = teamGroups.length;
  const teamsCompleted = teamGroups.filter(
    (tg) => tg.status === 'FINISHED',
  ).length;
  const teamsStarted =
    teamGroups.filter((tg) => tg.status === 'STARTED').length +
      teamsCompleted || 0;

  // if team based then populate with teams instead
  const participantsFinishedAssessment = teamBased
    ? teamGroups
        .filter((tg) => tg.status === 'FINISHED')
        ?.map((t) => ({ userId: t?.group?.id, userName: t?.group?.name }))
        .sort(sortObjectArrayByField('userName'))
    : usersData?.listUsersByAssessmentId?.items
        .filter((pc) => pc.status === 'FINISHED')
        ?.map((u) => ({ userId: u?.user?.id, userName: u?.user?.name }))
        .sort(sortObjectArrayByField('userName')) || [];

  // all skills in the assessment
  const allSkills = _.chain(
    tasks?.items
      ?.map(({ task }) =>
        task?.skills?.items?.map(({ skill }) => ({
          skillId: skill?.id,
          skillName: skill?.name,
          skillAlias: skill?.alias,
        })),
      )
      ?.flat()
      ?.filter((a) => a?.skillId),
  )
    .uniqBy('skillId')
    .sortBy('skillName')
    ?.map((nc) => {
      const tasksCount =
        tasks?.items?.filter((t) =>
          t.task?.skills?.items?.some(({ skill }) => skill.id === nc?.skillId),
        )?.length || 0;
      return {
        ...nc,
        caption: `${tasksCount} tasks`,
        tasksCount,
      };
    })
    .value();

  const onParticipantClick = (_name) => {
    // redirect to insights tab of a particular clicked participant
    setCustIndex(1);
    setDefaultInsight(_name);
  };

  const Tile = ({ title, description }) => (
    <Box display="flex" alignItems="center" flexDirection="column">
      <FdTypography variant="subtitle1">{title}</FdTypography>
      <FdTypography variant="captiontext1" color="secondary">
        {description}
      </FdTypography>
    </Box>
  );
  Tile.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  };

  const durationFormatted = getFormattedDuration(hours, minutes);
  const participantsFinished =
    usersData?.listUsersByAssessmentId?.items?.filter(
      (td) => td.status === 'FINISHED',
    );

  const totalAssessmentPoints = tasks?.items.reduce(
    (acc, i) => acc + i.task?.recommendedPoints,
    0,
  );

  const totalPointsScoredInAssessment = completedTasks?.reduce(
    (acc, i) => acc + i?.points,
    0,
  );

  const maxDurationInMins = hours * 60 + minutes;

  const rankingNormalizedRows = getRankingNormalizedRows({
    _participantsFinished: participantsFinished,
    _completedTasks: completedTasks,
    _allTaskAttempts: allTaskAttempts,
    _totalAssessmentPoints: totalAssessmentPoints,
    _maxDurationInMins: maxDurationInMins,
    _timeSpentData: timeSpentDataForAssessment,
    _totalPointsScoredInAssessment: totalPointsScoredInAssessment,
    _teamBased: teamBased,
    _teams: participantsFinishedAssessment,
    _teamGroups: teamGroups,
    _tasksOpened: tasksOpened,
  });

  const {
    rankingTableRows,
    avgOverallScore,
    successPoolAverage,
    efficiencyPoolAverage,
    speedPoolAverage,
  } = getRankingTableRows({
    _participantsFinished: participantsFinished,
    _rankingNormalizedRows: rankingNormalizedRows,
  });

  const chartCohortRow = {
    id: 'Cohort Average',
    userId: 0,
    data: allSkills?.map((s) => {
      const skillTasks = filterBySkill(tasks?.items, s.skillName);
      const skillTotalAssessmentPoints = skillTasks?.reduce(
        (acc, i) => acc + i.task?.recommendedPoints,
        0,
      );

      const normalizedRows = getRankingNormalizedRows({
        _participantsFinished: participantsFinished,
        _completedTasks: filterBySkill(completedTasks, s.skillName),
        _allTaskAttempts: filterBySkill(allTaskAttempts, s.skillName),
        _totalAssessmentPoints: skillTotalAssessmentPoints,
        _maxDurationInMins: maxDurationInMins,
        _timeSpentData: timeSpentDataForAssessment,
        _totalPointsScoredInAssessment: totalPointsScoredInAssessment,
        _teamBased: teamBased,
        _teams: participantsFinishedAssessment,
        _teamGroups: teamGroups,
        _tasksOpened: tasksOpened,
      });

      return {
        x: s.skillAlias,
        y:
          rankingNormalizedRows?.length > 0
            ? normalizedRows?.reduce(
                (acc, i) => acc + i?.normalizedSuccess,
                0,
              ) / rankingNormalizedRows?.length
            : 0,
      };
    }),
  };
  const comparisonData = {
    rankingNormalizedRows,
    rankingTableRows,
    chartCohortRow,
    allSkills,
    tasks,
    participantsFinished,
    completedTasks,
    allTaskAttempts,
    maxDurationInMins,
    timeSpentData: timeSpentDataForAssessment,
    loading,
    teamBased,
    totalPointsScoredInAssessment,
    participantsFinishedAssessment,
    teamGroups,
    tasksOpened,
  };
  const allOverallScores =
    rankingNormalizedRows?.map((r) =>
      r?.overallScore <= 0 ? 1 : r?.overallScore,
    ) || [];
  const getRangeCohort = (rangeStart, rangeEnd) =>
    allOverallScores.filter((os) => os >= rangeStart && os <= rangeEnd)
      ?.length || 0;
  // bar char shows interval in 20 units from a range 0-100
  const barChartData = Array.from({ length: 5 }, (__, index) => {
    const rangeStart = index * 20;
    const rangeEnd = rangeStart + 20;
    return {
      range: `${rangeStart}-${rangeEnd}`,
      'Assessment Score': getRangeCohort(rangeStart + 1, rangeEnd),
    };
  });
  const positiveRangeValues =
    barChartData?.filter((bc) => bc['Assessment Score'] > 0) || [];
  const lowestBarRange =
    _.minBy(positiveRangeValues, 'Assessment Score')?.range || 0;
  const highestBarRange =
    _.maxBy(positiveRangeValues, 'Assessment Score')?.range || 0;
  // triangle radar graph data
  const getValueIfFinite = (selector) =>
    Number.isFinite(selector) ? Math.round(selector, 2) : 0;

  const { successRange, speedRange, efficiencyRange } =
    rankingTableRows[0] ?? {};

  const radarRangeLabel = {
    Success: {
      min:
        Math.round(
          _.minBy(rankingNormalizedRows, 'normalizedSuccess')
            ?.normalizedSuccess,
        ) || 0,
      max:
        Math.round(
          _.maxBy(rankingNormalizedRows, 'normalizedSuccess')
            ?.normalizedSuccess,
        ) || 0,
    },
    Speed: {
      min:
        Math.round(
          _.minBy(rankingNormalizedRows, 'normalizedSpeed')?.normalizedSpeed,
        ) || 0,
      max:
        Math.round(
          _.maxBy(rankingNormalizedRows, 'normalizedSpeed')?.normalizedSpeed,
        ) || 0,
    },
    Efficiency: {
      min:
        Math.round(
          _.minBy(rankingNormalizedRows, 'normalizedEfficiency')
            ?.normalizedEfficiency,
        ) || 0,
      max:
        Math.round(
          _.maxBy(rankingNormalizedRows, 'normalizedEfficiency')
            ?.normalizedEfficiency,
        ) || 0,
    },
  };
  const getCohortAvgValue = (_field) =>
    rankingNormalizedRows?.length > 0
      ? Math.round(
          rankingNormalizedRows?.reduce((acc, i) => acc + i[_field], 0) /
            rankingNormalizedRows?.length,
          2,
        )
      : 0;
  const radarChartData = [
    {
      label: 'Success',
      Lowest: getValueIfFinite(successRange?.min),
      'Cohort Average': getCohortAvgValue('normalizedSuccess'),
      Highest: getValueIfFinite(successRange?.max),
      baseLine: 100,
    },
    {
      label: 'Speed',
      Lowest: getValueIfFinite(speedRange?.min),
      'Cohort Average': getCohortAvgValue('normalizedSpeed'),
      Highest: getValueIfFinite(speedRange?.max),
      baseLine: 100,
    },
    {
      label: 'Efficiency',
      Lowest: getValueIfFinite(efficiencyRange?.min),
      'Cohort Average': getCohortAvgValue('normalizedEfficiency'),
      Highest: getValueIfFinite(efficiencyRange?.max),
      baseLine: 100,
    },
  ];

  // no participation then show no data present
  const noDataToPresent =
    !loading &&
    (teamBased ? teamsCompleted === 0 : participantsCompleted === 0);
  const NoDataToPresent = () => (
    <Box display="flex" justifyContent="center">
      <img src={NoDataPresentSvg} alt="no data present" />
    </Box>
  );
  const participantsOrTeams = teamBased ? 'Teams' : 'Participants';
  const teamOrParticipantText = teamBased ? 'teams' : 'participants';
  const completionRatio =
    (teamBased ? teamsCompleted : participantsCompleted) || 1;
  const completedRatio = completedTasks?.length / completionRatio;
  const averageSolvesValue =
    notStarted ||
    (teamBased ? teamsCompleted === 0 : participantsCompleted === 0)
      ? '-'
      : `${Math.round(completedRatio)}/${tasks?.items.length}`;

  return (
    <Box>
      <Summary
        loading={!assessmentData}
        data={[
          ...(teamBased
            ? [
                {
                  value: (
                    <Box mb={1}>
                      <FdTypography variant="subtitle1">Team</FdTypography>
                    </Box>
                  ),
                  description: 'Assessment Type',
                },
              ]
            : []),
          {
            value: (
              <Box mb={1}>
                <FdChip color={statusColor} size="small" label={status} />
              </Box>
            ),
            description: 'Assessment Status',
          },
          {
            value: <Box mb={1}>{getDateTimeZoneFormatted(startDateTime)}</Box>,
            description: 'Start Date',
          },
          {
            value: <Box mb={1}>{getDateTimeZoneFormatted(endDateTime)}</Box>,
            description: 'End Date',
          },
          ...(durationFormatted
            ? [
                {
                  value: <Box mb={1}>{durationFormatted}</Box>,
                  description: 'Duration',
                },
              ]
            : []),
          ...(status === 'In Progress' && endDateTime
            ? [
                {
                  value: (
                    <Box mb={1}>
                      <FdSystemDateCountdown endDateTime={endDateTime} />
                    </Box>
                  ),
                  description: 'Time remaining',
                },
              ]
            : []),
        ]}
        titleVariant="subtitle1"
        subtitleVariant="subtitle2"
      />
      <Box mt={2}>
        <FdCard
          variant="outlined"
          heading="How is the engagement and performance in this assessment?"
          subHeading={`This section provides an overview of the cohort's performance in this assessment, which is assessed based on the number of challenges solved as well as the efficiency and speed with which ${teamOrParticipantText} tackled the assessment.`}
        >
          <Box>
            <FdCardList
              variant="outlined"
              data={[
                <Box display="flex" alignItems="center">
                  <Box mr={1} pt={0.7}>
                    <BarChart />
                  </Box>
                  <FdTypography variant="h4">Engagement</FdTypography>
                </Box>,
                <Tile
                  title={teamBased ? teamGroupsCount : participantsCount}
                  description={`${participantsOrTeams} Invited`}
                />,
                <Tile
                  title={teamBased ? teamsStarted : participantsStarted}
                  description={`${participantsOrTeams} Started`}
                />,
                <Tile
                  title={teamBased ? teamsCompleted : participantsCompleted}
                  description={`${participantsOrTeams} Completed`}
                />,
              ]}
            />
          </Box>
          <Box mt={2}>
            <FdCard variant="outlined">
              <Box display="flex">
                <Box width="50%">
                  <FdTypography variant="body1">
                    Performance Triangle
                  </FdTypography>
                  <Box mt={1}>
                    <FdTypography variant="captiontext1" color="secondary">
                      {`The performance triangle displays overall ${
                        teamBased ? 'team' : 'participant'
                      }
                      assessment score incorporating Success, Efficiency and
                      Speed metrics. The blue-shaded area represents the
                      spectrum of ${
                        teamBased ? 'team' : 'participant'
                      } results, while the dark grey line
                      represents the cohort average.`}
                    </FdTypography>
                    <Box mt={2} mb={1}>
                      <FdTypography variant="captiontext1" color="secondary">
                        Click on the info icons for metrics definitions
                      </FdTypography>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <TableHeaderColumnWithTooltip
                      title="Success"
                      tooltipText={
                        <Box>
                          Success is defined by the points earned by
                          successfully solving challenges where:
                          <FdTypography variant="captiontext2">
                            0= No points achieved
                          </FdTypography>
                          <FdTypography variant="captiontext2">
                            100= All Points achieved
                          </FdTypography>
                        </Box>
                      }
                      mr={3}
                    />
                    <TableHeaderColumnWithTooltip
                      title="Efficiency"
                      tooltipText={
                        <Box>
                          Efficiency is the
                          {teamBased ? ' team' : ' participant'}
                          &apos;s ability to score points in least number of
                          tries where:
                          <FdTypography variant="captiontext2">
                            0= All challenges entered but not solved
                          </FdTypography>
                          <FdTypography variant="captiontext2">
                            100= All challenges entered are solved
                          </FdTypography>
                        </Box>
                      }
                      mr={3}
                    />
                    <TableHeaderColumnWithTooltip
                      title="Speed"
                      tooltipText={
                        <Box>
                          Speed is determined by the
                          {teamBased ? ' team' : ' participant'}
                          &apos;s capability to complete the assessment in the
                          quickest possible time where:
                          <FdTypography variant="captiontext2">
                            0=0 solves in complete assessment duration
                          </FdTypography>
                          <FdTypography variant="captiontext2">
                            100= 100% solves in just 1% of assessment duration
                          </FdTypography>
                        </Box>
                      }
                    />
                  </Box>
                  {noDataToPresent ? (
                    <NoDataToPresent />
                  ) : (
                    <FdSkeleton loading={loading} height="350px">
                      <Box height="350px">
                        <RadarNivo
                          data={radarChartData}
                          keys={[
                            'baseLine',
                            'Highest',
                            'Cohort Average',
                            'Lowest',
                          ]}
                          indexBy="label"
                          colors={[
                            'transparent',
                            '#90CAF9',
                            'transparent',
                            'white',
                          ]}
                          gridLabel={(props) => {
                            // eslint-disable-next-line react/prop-types
                            const { x, y, id } = props;
                            const anchor =
                              x > 5 ? 'start' : x < -5 ? 'end' : 'middle';
                            const lineData = radarChartData?.find(
                              (d) => d.label === id,
                            );
                            return (
                              <g
                                transform={`translate(${
                                  x + (id === 'Success' ? 30 : 0)
                                }, ${y})`}
                              >
                                <text
                                  alignmentBaseline="middle"
                                  textAnchor={
                                    id === 'Success' ? 'start' : anchor
                                  }
                                  fontSize={14}
                                  fontFamily="Montserrat, sans-serif"
                                  fontWeight={700}
                                  opacity={0.7}
                                  fill={isDarkTheme ? '#fff' : '#000'}
                                >
                                  {id}
                                  <tspan
                                    fontSize={14}
                                    fontWeight={400}
                                    x={0}
                                    dy={25}
                                  >
                                    {`Cohort Average ${lineData['Cohort Average']}`}
                                  </tspan>
                                  <tspan
                                    fontSize={14}
                                    fontWeight={400}
                                    x={0}
                                    dy={20}
                                    opacity={0.7}
                                  >
                                    {`Lowest ${radarRangeLabel[id]?.min}, Highest ${radarRangeLabel[id]?.max}`}
                                  </tspan>
                                </text>
                              </g>
                            );
                          }}
                          borderColor={(v) =>
                            v.key === 'Cohort Average'
                              ? '#424242'
                              : v.key === 'baseLine'
                              ? 'rgba(0, 0, 0, 0.12)'
                              : 'transparent'
                          }
                        />
                      </Box>
                    </FdSkeleton>
                  )}
                </Box>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: '0 1rem' }}
                />
                <Box width="50%">
                  <FdTypography variant="body1">
                    Performance across the cohort
                  </FdTypography>
                  <Box mt={1}>
                    <FdTypography variant="captiontext1" color="secondary">
                      {` The histogram groups ${teamOrParticipantText} by Assessment
                      score. Assessment score is a function of Success,
                      Efficiency, and Speed, weighted in that order.`}
                    </FdTypography>
                  </Box>
                  {noDataToPresent ? (
                    <Box mt={6}>
                      <NoDataToPresent />
                    </Box>
                  ) : (
                    <FdSkeleton loading={loading} height="395px">
                      <Box height="350px">
                        <BarNivo
                          data={barChartData}
                          keys={['Assessment Score']}
                          indexBy="range"
                          colors={['#35C3A1']}
                          yAxisLegend={`No. of ${teamOrParticipantText}`}
                          axisBottom={{
                            legend: 'Assessment Score',
                            legendPosition: 'middle',
                            legendOffset: 35,
                            tickSize: 0,
                          }}
                          axisLeft={{
                            legendPosition: 'middle',
                            legend: `No. of ${teamOrParticipantText}`,
                            legendOffset: -80,
                            tickValues: 4,
                            format: (e) => Math.floor(e) === e && e,
                          }}
                          enableLabel={false}
                          padding={0.05}
                        />
                      </Box>
                    </FdSkeleton>
                  )}
                  {lowestBarRange !== highestBarRange && (
                    <Box>
                      <FdTypography variant="body2">
                        {`The largest group has assessment scores between the range of ${highestBarRange}. Conversely, there is a smaller
                      group of candidates whose scores fall within the range ${lowestBarRange}.`}
                      </FdTypography>
                    </Box>
                  )}
                </Box>
              </Box>
            </FdCard>
          </Box>
        </FdCard>
      </Box>
      <Box mt={2}>
        <FdCard
          variant="outlined"
          heading="Who were the best performers?"
          subHeading={
            <Box>
              <FdTypography variant="body2" color="secondary">
                {`This section gives you the list of most successful ${teamOrParticipantText} along with their assessment ranks.`}
              </FdTypography>
              <Box mt={1}>
                <FdTypography variant="body2" color="secondary">
                  The assessment rank is determined by the combined assessment
                  score, which is heavily influenced by Success(challenges
                  successfully solved), while also taking in account
                  Efficiency(scoring points with least number of tries), and
                  Speed(quickness in completing the assessment).
                </FdTypography>
              </Box>
            </Box>
          }
        >
          {noDataToPresent ? (
            <Box mt={6}>
              <NoDataToPresent />
            </Box>
          ) : (
            <Box>
              <FdCardList
                variant="outlined"
                data={[
                  <Box display="flex" alignItems="center">
                    <Box mr={1} pt={0.7}>
                      <MilitaryTech />
                    </Box>
                    <FdTypography variant="h4">Leaderboard</FdTypography>
                  </Box>,
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Box
                      py={0.5}
                      px={2}
                      mb={1}
                      style={{
                        backgroundColor: '#6A6A6A',
                        borderRadius: '4px',
                        color: 'white',
                      }}
                    >
                      <FdTypography variant="subtitle1">
                        {avgOverallScore}
                      </FdTypography>
                    </Box>
                    <FdTypography variant="captiontext1">
                      Average Assessment Score
                    </FdTypography>
                  </Box>,
                  <Tile
                    title={
                      <FdSkeleton
                        loading={loading}
                        variant="text"
                        height="24px"
                        width="30px"
                      >
                        {averageSolvesValue}
                      </FdSkeleton>
                    }
                    description="Average Solves"
                  />,
                  <Tile
                    title={successPoolAverage}
                    description="Average Success"
                  />,
                  <Tile
                    title={efficiencyPoolAverage}
                    description="Average Efficiency"
                  />,
                  <Tile title={speedPoolAverage} description="Average Speed" />,
                ]}
              />
              <ComparisonRanking
                data={comparisonData}
                onParticipantClick={onParticipantClick}
              />
            </Box>
          )}
        </FdCard>
        <Box mt={1}>
          {noDataToPresent ? (
            <FdCard
              heading="Challenge Completion"
              subHeading={`Challenge Completion Heatmap shows the challenges completed by each ${
                teamBased ? 'team' : 'participant'
              } and the time spent by them on each challenge.  Each cell will be colour-coded by the success rate of the ${
                teamBased ? 'team' : 'participant'
              } in attempting that challenge, where success is determined by number of attempts by the ${
                teamBased ? 'team' : 'participant'
              }.`}
            >
              <NoDataToPresent />
            </FdCard>
          ) : (
            <FdSkeleton
              loading={usersDataLoading}
              variant="rect"
              height={538}
              animation="wave"
            >
              <TaskCompletionTable
                allTasks={allTasksForCompletionTable}
                participants={participantsFinishedAssessment}
                onParticipantClick={onParticipantClick}
                teamBased={teamBased}
                timeSpentData={timeSpentData}
                teamGroups={teamGroups}
                allTaskAttempts={allTaskAttempts}
                loading={loading}
              />
            </FdSkeleton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

Overview.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  assessmentData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCustIndex: PropTypes.func,
  setDefaultInsight: PropTypes.func,
  attemptsData: PropTypes.shape({
    listTaskAttemptAggregatesByAssessmentId: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }).isRequired,
  serverTime: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  timeSpentDataForAssessment: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tasksOpened: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

Overview.defaultProps = {
  setCustIndex: () => {},
  setDefaultInsight: () => {},
};

export default Overview;
