import _ from 'lodash';
import getStandardDeviation from '../../shared/utils/mathUtils';

const getStatusCompleted = (status) => status === 'COMPLETED';

const getStatusAttempted = (status) => status === 'ATTEMPTED';

const calculateAttempts = (items, getStatusFn) => {
  return items?.reduce(
    (acc, i) => acc + (getStatusFn(i.status) ? i.attempts : 0),
    0,
  );
};

const getTimeSpentByUserIds = (_userIds, _timeSpentData) =>
  _timeSpentData?.reduce(
    (acc, i) =>
      acc + (_userIds.includes(i.userId) ? Math.round(i.duration) : 0),
    0,
  ) || 0;

export const getEfficiency = (
  _userIds,
  _allTaskAttempts,
  _totalAssessmentPoints,
  _startSolvingRows,
) => {
  const allAttempts =
    _allTaskAttempts?.filter((a) => _userIds.includes(a.userId)) || [];
  const userStartedSolving =
    _startSolvingRows?.filter((s) => _userIds.includes(s.userId)) || [];
  const taskWiseAttempts = _(allAttempts)
    .groupBy('taskId')
    .map((items, taskId) => {
      const points = items[0]?.task?.recommendedPoints;
      const pointsScored = items.some((i) => getStatusCompleted(i.status))
        ? points
        : 0;
      /*
       Multiplier = Points max in assessment / Points for the task
      */
      const multiplier = points > 0 ? _totalAssessmentPoints / points : 0;
      const attemptsCount = items?.reduce((acc, i) => acc + i.attempts, 0) || 0;
      const attemptsCompleted = calculateAttempts(items, getStatusCompleted);
      const attemptsAttempted = calculateAttempts(items, getStatusAttempted);
      // since aggregates table contains summation, completed might have attempted as well
      const unsuccessfulAttempts =
        pointsScored > 0
          ? attemptsCompleted > 1
            ? attemptsCompleted - 1 + attemptsAttempted
            : attemptsAttempted
          : attemptsAttempted;
      /*
       Success Rate = Attempts total - Unsuccessful Attempts / Attempts total
      */
      const successRate =
        (attemptsCount - unsuccessfulAttempts) / attemptsCount;
      return {
        taskId,
        multiplier,
        pointsScored,
        successRate,
        taskName: items[0]?.task?.name,
      };
    })
    .value();

  const attemptsTaskIds = taskWiseAttempts?.map((t) => t.taskId);
  const onlyStarted = userStartedSolving?.filter(
    (us) => !attemptsTaskIds.includes(us.taskOpenedTaskId),
  );

  const onlyStartedAttempts = _(onlyStarted)
    .groupBy('taskOpenedTaskId')
    .map((items, taskOpenedTaskId) => {
      const points = items[0]?.task?.recommendedPoints;
      const multiplier = points > 0 ? _totalAssessmentPoints / points : 0;
      return {
        taskOpenedTaskId,
        multiplier,
        successRate: 0,
        taskName: items[0]?.task?.name,
        startedSolvingAt: items[0]?.startedSolvingAt,
        points,
        _totalAssessmentPoints,
      };
    })
    .value();

  /* 
    Efficiency = Sum of (Success Rate * Multiplier) / Sum of (Multipliers)
  */
  const sumOfSuccessRates =
    taskWiseAttempts?.reduce(
      (acc, i) => acc + i.successRate * i.multiplier,
      0,
    ) +
    onlyStartedAttempts?.reduce(
      (acc, i) => acc + i.successRate * i.multiplier,
      0,
    );

  const sumOfMultipliers =
    taskWiseAttempts?.reduce((acc, i) => acc + i.multiplier, 0) +
    onlyStartedAttempts?.reduce((acc, i) => acc + i.multiplier, 0);

  return sumOfMultipliers > 0
    ? (sumOfSuccessRates / sumOfMultipliers) * 100
    : 0;
};

const getSpeed = (
  _userIds,
  _completedTasks,
  _totalPointsScoredInAssessment,
  _maxDurationInMins,
  _timeSpentData,
) => {
  const allAttempts =
    _completedTasks?.filter((a) => _userIds.includes(a.userId)) || [];

  const pointsScored = allAttempts.reduce(
    (a, o) => a + o.task?.recommendedPoints,
    0,
  );
  /* 
    Speed = 100 * (Points scored by candidate / Max points scored in the assessment pool) /
     1 + (Time taken by candidate / Max time allocated for the assessment)
  */
  const timeSpentInMins = getTimeSpentByUserIds(_userIds, _timeSpentData);
  const speed =
    (100 * (pointsScored / _totalPointsScoredInAssessment)) /
    (1 + timeSpentInMins / _maxDurationInMins);

  return Number.isFinite(speed) ? speed : 0;
};

export const getRankingNormalizedRows = ({
  _participantsFinished,
  _completedTasks,
  _allTaskAttempts,
  _totalAssessmentPoints,
  _maxDurationInMins,
  _timeSpentData,
  _totalPointsScoredInAssessment,
  _teams,
  _teamGroups,
  _teamBased,
  _tasksOpened,
}) => {
  const _teamsOrParticipants = _teamBased ? _teams : _participantsFinished;
  const startSolvingRows =
    _tasksOpened?.filter((to) => to?.startedSolving) || [];
  const rankingRows =
    _teamsOrParticipants?.map((p) => {
      const userIds = _teamBased
        ? _teamGroups
            ?.find((t) => t.groupId === p.userId)
            ?.group?.users?.items.map((u) => u?.user?.id)
        : [p?.user?.id];
      return {
        id: _teamBased ? p.userId : p?.user?.id,
        name: _teamBased ? p.userName : p?.user?.name,
        totalPoints:
          _completedTasks
            ?.filter((c) => userIds.includes(c.userId))
            .reduce((acc, i) => acc + i?.task?.recommendedPoints, 0) || 0,
        efficiency: getEfficiency(
          userIds,
          _allTaskAttempts,
          _totalAssessmentPoints,
          startSolvingRows,
        ),
        speed: getSpeed(
          userIds,
          _completedTasks,
          _totalAssessmentPoints,
          _maxDurationInMins,
          _timeSpentData,
        ),
      };
    }) || [];

  const rankingNormalizedRows =
    rankingRows
      ?.map((p) => {
        /* 
        NSc - Normalized Success
        NSc = (Pcand) / (Ptot) * 100
        Pcand - Candidate total points in assessment
        Ptot - Total possible points in assessment
      */
        const normalizedSuccess =
          (p.totalPoints / _totalAssessmentPoints) * 100;
        /*
        Overall Score
        OS = (0.7 * NSc) + (0.2 * NEf) + (0.1 * NSp)
      */
        const overallScore = Math.round(
          0.7 * normalizedSuccess + 0.15 * p.efficiency + 0.15 * p.speed,
        );

        return {
          ...p,
          overallScore,
          normalizedSuccess,
          normalizedEfficiency: p.efficiency,
          normalizedSpeed: p.speed,
        };
      })
      .sort((a, b) => b.overallScore - a.overallScore)
      .map((r, idx) => ({ ...r, rank: idx + 1 })) || [];

  return rankingNormalizedRows;
};

export const filterBySkill = (_tasks, _skill) =>
  _tasks?.filter(({ task }) =>
    task?.skills?.items?.map(({ skill }) => skill?.name).includes(_skill),
  );

// Aggregates
export const getRankingTableRows = ({
  _participantsFinished,
  _rankingNormalizedRows,
}) => {
  const getPoolAverage = (_column) =>
    _participantsFinished?.length > 0
      ? _rankingNormalizedRows?.reduce((acc, i) => acc + i[_column], 0) /
        _participantsFinished?.length
      : 0;
  const avgOverallScore = Math.round(getPoolAverage('overallScore') || 0);
  const successPoolAverage = Math.round(
    getPoolAverage('normalizedSuccess') || 0,
  );
  const efficiencyPoolAverage = Math.round(
    getPoolAverage('normalizedEfficiency') || 0,
  );
  const speedPoolAverage = Math.round(getPoolAverage('normalizedSpeed') || 0);
  const overallScorePoolAverage = Math.round(
    getPoolAverage('overallScore') || 0,
  );

  const getValueRange = (_column, poolAverage) => {
    const values = _rankingNormalizedRows?.map((i) => i[_column]);
    const std = getStandardDeviation(values);
    const min = poolAverage - std;
    const max = poolAverage + std;
    return { min: min < 0 ? 0 : min, max: max > 100 ? 100 : max };
  };

  const getAssessmentRange = (_column) => {
    return {
      min: _.minBy(_rankingNormalizedRows, _column)?.[_column] || 0,
      max: _.maxBy(_rankingNormalizedRows, _column)?.[_column] || 0,
    };
  };

  const rankingTableRows = _rankingNormalizedRows?.map((r) => {
    return {
      ...r,
      successRange: getValueRange('normalizedSuccess', successPoolAverage),
      assessmentSuccessRange: getAssessmentRange('normalizedSuccess'),
      efficiencyRange: getValueRange(
        'normalizedEfficiency',
        efficiencyPoolAverage,
      ),
      assessmentEfficiencyRange: getAssessmentRange('normalizedEfficiency'),
      speedRange: getValueRange('normalizedSpeed', speedPoolAverage),
      assessmentSpeedRange: getAssessmentRange('normalizedSpeed'),
      overallScoreRange: getValueRange('overallScore', overallScorePoolAverage),
      assessmentOverallScoreRange: getAssessmentRange('overallScore'),
    };
  });

  return {
    rankingTableRows,
    avgOverallScore,
    successPoolAverage,
    efficiencyPoolAverage,
    speedPoolAverage,
    overallScorePoolAverage,
  };
};
