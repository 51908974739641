import React from 'react';
import { usePDF } from '@react-pdf/renderer';
import InsightsPdfReport from '../InsightsPdfReport';

const useDownloadPdf = ({
  userName,
  assessmentName,
  chartImages,
  logoImageKey,
  logoImageUrl,
  ...props
}) => {
  const [instance, updateInstance] = usePDF({
    document: (
      <InsightsPdfReport
        userName={userName}
        assessmentName={assessmentName}
        techniqueChartImage={chartImages['technique-chart']}
        technologyChartImage={chartImages['technology-chart']}
        performanceSkillsChartImage={chartImages['performance-skills-chart']}
        performanceTriangleChartImage={
          chartImages['performance-triangle-chart']
        }
        heatmapChartImage={chartImages['heatmap-chart']}
        logoUrl={logoImageKey && logoImageUrl}
        {...props}
      />
    ),
  });

  return { instance, updateInstance };
};

export default useDownloadPdf;
