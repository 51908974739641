import React from 'react';
import PropTypes from 'prop-types';
import ShowMoreText from 'react-show-more-text';
import { Box } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';

const FdTextView = ({ label, value }) => {
  return (
    <Box>
      <FdTypography variant="subtitle1">{label}</FdTypography>
      <FdTypography variant="body1" color="secondary">
        <ShowMoreText
          lines={10}
          more="See more"
          less="See less"
          anchorClass="see-more"
        >
          <Box width="363px" style={{ overflow: 'auto' }}>
            {value.split('\n').map((v) => (
              <span>
                {v}
                <br />
              </span>
            ))}
          </Box>
        </ShowMoreText>
      </FdTypography>
    </Box>
  );
};

FdTextView.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

FdTextView.defaultProps = {
  label: '',
  value: '',
};

export default FdTextView;
