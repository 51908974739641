import React from 'react';
import { FdCountdown, useSnapshot } from '@fifthdomain/fe-shared';
import appStore from '../store';

const FdSystemDateCountdown = ({ ...props }) => {
  const snap = useSnapshot(appStore);
  return <FdCountdown systemDate={snap.systemDate} {...props} />;
};

export default FdSystemDateCountdown;
