import { useEffect } from 'react';
import { captureChartImage } from '../captureChartImage';

const useImageCapture = ({
  userName,
  chartImages,
  setChartImages,
  instanceUpdated,
  setInstanceUpdated,
  updateInstance,
  totalImageCount,
}) => {
  useEffect(() => {
    let isMounted = true;
    const captureImageForChart = async (chartName, timeOut) => {
      return new Promise((resolve) => {
        const timeOutId = setTimeout(() => {
          if (isMounted) {
            captureChartImage((image) => {
              setChartImages((prevImages) => ({
                ...prevImages,
                [chartName]: image,
              }));
              resolve();
            }, chartName);
          }
        }, timeOut);
        return timeOutId;
      });
    };

    const captureImages = async () => {
      const chartData = [
        { name: 'heatmap-chart', timeOut: 200 },
        { name: 'technique-chart', timeOut: 200 },
        { name: 'technology-chart', timeOut: 200 },
        { name: 'performance-skills-chart', timeOut: 200 },
        { name: 'performance-triangle-chart', timeOut: 200 },
      ];
      const timeOutIds = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const { name, timeOut } of chartData) {
        // eslint-disable-next-line no-await-in-loop
        const timeOutId = await captureImageForChart(name, timeOut);
        timeOutIds.push(timeOutId);
      }
      return timeOutIds;
    };

    captureImages();

    return () => {
      isMounted = false; // Set the component as unmounted
      // clear timeouts
      captureImages().then((timeOutIds) =>
        timeOutIds.forEach((id) => clearTimeout(id)),
      );
    };
  }, [userName, setChartImages]);

  useEffect(() => {
    if (
      !instanceUpdated &&
      Object.keys(chartImages).length === totalImageCount
    ) {
      updateInstance();
      setInstanceUpdated(true);
    }
  }, [
    chartImages,
    updateInstance,
    setInstanceUpdated,
    instanceUpdated,
    totalImageCount,
  ]);
};

export default useImageCapture;
