import React, { useEffect, useContext } from 'react';
import { Route, useHistory, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import singleSpa from 'single-spa';
import {
  AuthContext,
  FdProgress,
  globalStore,
  useSnapshot,
  Authorization,
} from '@fifthdomain/fe-shared';
import OptionalOnboardingRoute from './OptionalOnboardingRoute';
import setAppMarginRightByIds from '../shared/utils/layout';
import UserAssessmentProvider from '../providers/UserAssessmentProvider';

const ParticipantRoute = ({ component: Component, ...rest }) => {
  const { user, setUser } = useContext(AuthContext);
  const globalSnap = useSnapshot(globalStore);
  const history = useHistory();
  const assessmentId = rest?.computedMatch.params?.assessmentId;
  const orgId = rest?.computedMatch.params?.orgId;
  const sharedLinkRoute =
    assessmentId && rest?.path === '/assessor/assess/:assessmentId/org/:orgId';

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(function (userData) {
        setUser(userData);
        // add logged in user to global state
        globalStore.user = userData;
      })
      .catch((err) => {
        console.log('error: ', err);
        // via custom url copy
        if (sharedLinkRoute) {
          window.location.replace(
            `${window.location.protocol}//${window.location.host}/a/login/e/assess/${assessmentId}/org/${orgId}`,
          );
        } else {
          history.push('/');
        }
      });
  }, [assessmentId, history, setUser, sharedLinkRoute, orgId]);

  if (globalSnap.loading) return <FdProgress />;

  return (
    <Route
      {...rest}
      render={(props) => {
        const type = globalSnap.userType;

        if (user) {
          const allowedManagerPermissions =
            Authorization.canManageEvents(globalSnap?.permissions) ||
            Authorization.canViewInsights(globalSnap?.permissions);

          if (type === 'PARTICIPANT') {
            setAppMarginRightByIds(['topnav'], '0'); // reset topnav to full width on page initialize

            if (sharedLinkRoute) {
              return <UserAssessmentProvider assessmentId={assessmentId} />;
            }
            // redirect participant to survey if they haven't done.
            return (
              <OptionalOnboardingRoute
                userId={user?.username}
                component={Component}
                {...rest}
                {...props}
              />
            );
          }
          if (
            type === 'ADMIN' ||
            (type === 'MANAGER' && allowedManagerPermissions)
          ) {
            return <Redirect to="/landing" />;
          }
          if (type === 'MANAGER' && !allowedManagerPermissions) {
            singleSpa.navigateToUrl('/user-management');
            return null;
          }
        }
        return null;
      }}
    />
  );
};

ParticipantRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default ParticipantRoute;
