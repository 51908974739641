const preMessageDefault = `

As part of your Cyber Skills Assessment, we place a significant emphasis not only on the accuracy, speed, and efficiency of your solutions but also on your ability to articulate your thought process. Your assessment will generate a report as described [here](https://au.intercom.help/fifth-domain/en/articles/2162-viewing-participant-insights-individual-based-assessment).

Your ability to leave detailed [notes](https://au.intercom.help/fifth-domain/en/articles/1808-participating-in-an-individual-based-assessment#h_f2066c1865) explaining your approach to solving each scenario is crucial and will be considered in your overall performance evaluation.

The assessment is designed that way to compel and measure task prioritisation. Before you begin the assessment, please familiarise yourself with the [online documentation](https://au.intercom.help/fifth-domain/en/articles/1808-participating-in-an-individual-based-assessment) for using the assessment platform., and using the supplied but OPTIONAL [Jump Box](https://au.intercom.help/fifth-domain/en/articles/2346-using-jump-box-in-your-competition). 

Good luck!`;

export default preMessageDefault;
