import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@material-ui/core';
import { LineNivo, RadarNivo } from '../../Charts';
import TechGraphs from '../Insights/TechGraphs';
import HeatmapNivo from '../../Charts/HeatmapNivo';
import HeatMapCustomLegend from '../HeatMapCustomLegend';
import useGetSvgWidthOnResize from '../../../hooks/useGetSvgWidthOnResize';

const ChartsForDownload = ({ chartsForDownloadData }) => {
  const [divRef, width, labelWidth, updateHeatMapWidth] =
    useGetSvgWidthOnResize();
  const theme = useTheme();
  const {
    loading,
    skillsProficienciesData,
    completedTasks,
    selectedUserIds,
    tasks,
    chartDataRows,
    radarChartData,
    teamOrParticipant,
    specialtyLegends,
  } = chartsForDownloadData ?? {};

  useEffect(() => {
    // update heatmap label
    setTimeout(() => {
      updateHeatMapWidth();
    }, 3000);
  }, [loading, updateHeatMapWidth]);

  return (
    <Box id="download-charts">
      <Box height="350px" id="performance-triangle-chart" width="1140px">
        <RadarNivo
          data={radarChartData}
          keys={['baseLine', `${teamOrParticipant} Score`, 'Cohort Average']}
          indexBy="label"
          colors={['transparent', 'transparent', 'transparent']}
          gridLabel={(props) => {
            // eslint-disable-next-line react/prop-types
            const { x, y, id } = props;
            const anchor = x > 5 ? 'start' : x < -5 ? 'end' : 'middle';
            const lineData = radarChartData?.find((d) => d.label === id);
            return (
              <g
                transform={`translate(${
                  x + (id === 'Success' ? 30 : 0)
                }, ${y})`}
              >
                <text
                  alignmentBaseline="middle"
                  textAnchor={id === 'Success' ? 'start' : anchor}
                  fontSize={14}
                  fontFamily="Montserrat, sans-serif"
                  fontWeight={700}
                  opacity={0.7}
                >
                  {id}
                  <tspan fontSize={14} fontWeight={400} x={0} dy={25}>
                    {`${teamOrParticipant} Score ${Math.round(
                      lineData[`${teamOrParticipant} Score`],
                    )}`}
                  </tspan>
                  <tspan
                    fontSize={14}
                    fontWeight={400}
                    x={0}
                    dy={20}
                    opacity={0.7}
                  >
                    {`Cohort Average ${Math.round(lineData['Cohort Average'])}`}
                  </tspan>
                </text>
              </g>
            );
          }}
          borderColor={(v) =>
            v.key === 'Cohort Average'
              ? '#424242'
              : v.key === 'baseLine'
              ? 'rgba(0, 0, 0, 0.12)'
              : theme?.palette?.primary?.main
          }
        />
      </Box>
      <Box height="271px" id="performance-skills-chart" width="992px">
        <LineNivo
          data={chartDataRows}
          colors={['#757575', theme?.palette?.primary?.main]}
          showDashedForId="Cohort Average"
        />
      </Box>
      <Box height="380px" id="heatmap-chart">
        <Box ref={divRef} height="100%">
          <HeatmapNivo
            customBottomLegend={
              <HeatMapCustomLegend
                width={width}
                labelWidth={labelWidth}
                specialtyLegends={specialtyLegends}
                forPdf
              />
            }
            data={skillsProficienciesData?.sort((a, b) => b.id - a.id) || []}
            yAxisLabel="Proficiency Levels"
            color="#3E3EB7"
            height="350px"
            theme={{
              fontSize: 20,
              fontFamily: 'Montserrat',
              axis: {
                legend: {
                  text: {
                    fontSize: 20,
                  },
                },
              },
            }}
          />
        </Box>
      </Box>
      <Box mt={3} height="300px">
        <TechGraphs
          loading={loading}
          tasks={tasks}
          completedTasks={completedTasks}
          selectedUserIds={selectedUserIds}
          containerHeight="360px"
          graphHeight="250px"
          forPdfDownload
        />
      </Box>
    </Box>
  );
};

ChartsForDownload.propTypes = {
  chartsForDownloadData: PropTypes.objectOf(PropTypes.shape({})).isRequired,
};

export default ChartsForDownload;
