import React from 'react';
import singleSpa from 'single-spa';
import PropTypes from 'prop-types';
import { Box, Divider } from '@material-ui/core';
import {
  FdCard,
  FdTypography,
  FdButton,
  S3Image,
} from '@fifthdomain/fe-shared';

const Dashboard = ({ dashboard }) => {
  const index = dashboard?.thumbnail?.key?.indexOf('/') + 1;
  const key = dashboard?.thumbnail?.key?.substring(index);
  return (
    <FdCard
      style={{ borderRadius: '16px' }}
      media={<S3Image image={key} size={{ width: '100%', height: '232px' }} />}
      heading={
        <Box>
          <FdTypography variant="h4">{dashboard.name}</FdTypography>
        </Box>
      }
      subHeading={
        <Box mb={2}>
          <Box mb={2}>
            <FdTypography variant="body1" color="secondary">
              {dashboard?.product?.name}
            </FdTypography>
          </Box>
          {dashboard?.description && (
            <Box mb={2}>
              <FdTypography color="primary" variant="body2">
                {dashboard.description}
              </FdTypography>
            </Box>
          )}
          <Divider />
        </Box>
      }
    >
      <Box display="flex" justifyContent="flex-start" padding="8px 0">
        <FdButton
          variant="tertiary"
          onClick={() =>
            singleSpa.navigateToUrl(`/assessor/dashboards/${dashboard?.id}`)
          }
        >
          VIEW
        </FdButton>
      </Box>
    </FdCard>
  );
};

Dashboard.propTypes = {
  dashboard: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    product: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    thumbnail: PropTypes.shape({
      key: PropTypes.string.isRequired,
      region: PropTypes.string.isRequired,
      bucket: PropTypes.string.isRequired,
    }),
    updatedAt: PropTypes.instanceOf(Date),
  }).isRequired,
};

export default Dashboard;
