import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Divider, Chip, makeStyles } from '@material-ui/core';
import { FdTypography, FdChip, FdSkeleton } from '@fifthdomain/fe-shared';
import { getParticipantAssessmentStatusColor } from '../../shared/utils/getStatusColor';
import {
  getDifficultyLabel,
  getDifficultyLabelColor,
} from '../../shared/utils/difficultyMapping';

const useStyles = makeStyles(() => ({
  condensedChip: {
    fontSize: 10,
    lineHeight: 14,
    borderRadius: '4px',
    height: '14px',
  },
}));

const TaskDrawerHeaderParticipant = ({
  activeTask,
  taskStatus,
  taskAttempts,
  loading,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box mb={2} className="flex gap-2">
        <Chip
          style={{
            backgroundColor: getDifficultyLabelColor(activeTask?.difficulty),
          }}
          size="small"
          label={getDifficultyLabel(activeTask?.difficulty)}
          className={classes.condensedChip}
        />
        <Chip
          size="small"
          label={`${activeTask?.recommendedPoints} pts`}
          className={classes.condensedChip}
        />
      </Box>
      <Card variant="outlined" className="py-3">
        <Box className="flex w-full justify-around">
          <Box className="flex flex-col items-center">
            <FdTypography variant="body1">{activeTask?.specialty}</FdTypography>
            <FdTypography
              variant="captiontext1"
              color="secondary"
              style={{ marginTop: '7px' }}
            >
              Specialty
            </FdTypography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box className="flex flex-col items-center">
            <FdSkeleton loading={loading} height={24} width={89}>
              <FdChip
                color={getParticipantAssessmentStatusColor(taskStatus)}
                size="medium"
                label={taskStatus}
                style={{ height: '24px' }}
              />
            </FdSkeleton>
            <FdTypography
              variant="captiontext1"
              color="secondary"
              style={{ marginTop: '7px' }}
            >
              Status
            </FdTypography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box className="flex flex-col items-center">
            <FdSkeleton loading={loading} height={24} width={49}>
              <FdTypography variant="subtitle1">{taskAttempts}</FdTypography>
            </FdSkeleton>
            <FdTypography
              variant="captiontext1"
              color="secondary"
              style={{ marginTop: '7px' }}
            >
              Attempts
            </FdTypography>
          </Box>
        </Box>
      </Card>
    </>
  );
};

TaskDrawerHeaderParticipant.propTypes = {
  activeTask: PropTypes.shape({
    specialty: PropTypes.string,
    type: PropTypes.string,
    solves: PropTypes.number,
    recommendedPoints: PropTypes.number,
    difficulty: PropTypes.string,
  }).isRequired,
  taskStatus: PropTypes.string.isRequired,
  taskAttempts: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TaskDrawerHeaderParticipant;
