import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { getDateTimeZoneCustom } from '../../shared/utils/dateUtils';

const Line = ({ data, colors, xaxisDateTimeMin, xaxisDateTimeMax }) => {
  const options = {
    series: data,
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        row: {
          colors,
        },
      },
      stroke: {
        curve: 'straight',
      },
      legend: {
        show: true,
        position: 'right',
      },
      xaxis: {
        type: 'datetime',
        min: xaxisDateTimeMin,
        max: xaxisDateTimeMax,
        labels: {
          formatter: (value) => getDateTimeZoneCustom(value, 'dd-EEE | HH:mm'),
        },
      },
    },
  };

  return (
    <Box data-cy="line-chart">
      <Chart
        options={options.options}
        series={options.series}
        type="line"
        width="100%"
        height="350"
      />
    </Box>
  );
};

Line.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  xaxisDateTimeMin: PropTypes.number,
  xaxisDateTimeMax: PropTypes.number,
  colors: PropTypes.arrayOf(PropTypes.string),
};

Line.defaultProps = {
  colors: [],
  xaxisDateTimeMin: undefined,
  xaxisDateTimeMax: undefined,
};

export default Line;
