import { gql } from '@apollo/client';
import { useQueryRecursive } from '@fifthdomain/fe-shared';
import { getAssessmentWithTasks } from '../queries/customQueries';

const useGetAssessmentsTasks = ({ userId }) =>
  useQueryRecursive(gql(getAssessmentWithTasks), {
    variables: {
      userId,
    },
  });

export default useGetAssessmentsTasks;
