import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Grid, makeStyles, Card, CardContent } from '@material-ui/core';
import { FdSkeleton } from '@fifthdomain/fe-shared';
import SummaryTitle from './SummaryTitle';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    alignItems: 'center',
  },
  card: {
    width: '100%',
    paddingTop: '4px',
    boxSizing: 'border-box',
  },
  itemCard: {
    '&:not(:last-child)': {
      borderRight: '1px solid #E0E0E0',
    },
  },
}));

const Summary = ({
  data,
  titleVariant,
  subtitleVariant,
  justifyContent,
  loading,
  titleColor,
  subtitleColor,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.card} data-cy="summary-card" variant="outlined">
      <CardContent>
        <Grid container className={classes.root}>
          {data.map((d, idx) => (
            <Grid
              item
              xs
              className={classes.itemCard}
              key={shortid.generate()}
              data-cy={`summary-${idx}`}
            >
              <Grid container justifyContent="center">
                <FdSkeleton
                  loading={loading}
                  animation="wave"
                  height={60}
                  width={200}
                >
                  <SummaryTitle
                    data={d}
                    titleVariant={titleVariant}
                    subtitleVariant={subtitleVariant}
                    justifyContent={justifyContent}
                    titleColor={titleColor}
                    subtitleColor={subtitleColor}
                  />
                </FdSkeleton>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

Summary.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
      ]),
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }).isRequired,
  ).isRequired,
  titleVariant: PropTypes.string,
  subtitleVariant: PropTypes.string,
  justifyContent: PropTypes.string,
  loading: PropTypes.bool,
  subtitleColor: PropTypes.string,
  titleColor: PropTypes.string,
};

Summary.defaultProps = {
  titleVariant: 'h3',
  subtitleVariant: 'subtitle1',
  justifyContent: 'center',
  loading: false,
  subtitleColor: 'secondary',
  titleColor: 'primary',
};

export default Summary;
