import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, IconButton } from '@material-ui/core';
import {
  FdTypography,
  FdTab,
  FdTooltip,
  FdIcons,
  FdSkeleton,
  FdCard,
} from '@fifthdomain/fe-shared';
import _ from 'lodash';
import Summary from './Summary';
import { getDuration } from '../../shared/utils/dateUtils';
import { BarNivo } from '../Charts';
import {
  getAssessmentChallengesTechniques,
  getAssessmentChallengesTechnologies,
  getSkillsProficienciesMappedData,
} from '../../shared/utils/getAssessmentOverview';
import HeatmapNivo from '../Charts/HeatmapNivo';
import { PROFICIENCY } from '../../constants';
import useGetSvgWidthOnResize from '../../hooks/useGetSvgWidthOnResize';
import HeatMapCustomLegend from './HeatMapCustomLegend';
import { getSpecialtyLegends } from './getSpecialtyLegends';

const AssessmentOverview = ({
  allTasks,
  tasks,
  hours,
  minutes,
  loading,
  specialtySkills,
}) => {
  const [divRef, width, labelWidth, updateHeatMapWidth] =
    useGetSvgWidthOnResize();
  const { InfoOutlined } = FdIcons;
  const tasksSelected = allTasks?.filter((a) => tasks.some((t) => t === a.id));
  const skillsProficienciesByProficiency = _.groupBy(
    tasksSelected.map((task) => ({
      proficiency:
        task.difficulty > 5 ? Math.ceil(task.difficulty / 2) : task.difficulty,
      skills: task.skills.items.map((_skill) => ({
        name: _skill.skill.name,
        alias: _skill.skill.alias,
      })),
    })),
    'proficiency',
  );

  const { specialtyLegends, skillsSorted } = getSpecialtyLegends({
    specialtySkills,
  });

  const skillsProficienciesData = getSkillsProficienciesMappedData(
    skillsProficienciesByProficiency,
    skillsSorted,
  );

  const assessmentChallengesTechniques =
    getAssessmentChallengesTechniques(tasksSelected);

  const assessmentChallengesTechnologies =
    getAssessmentChallengesTechnologies(tasksSelected);

  useEffect(() => {
    // update heatmap label
    setTimeout(() => {
      updateHeatMapWidth();
    }, 1000);
  }, [loading, updateHeatMapWidth]);

  const TechniquesTab = () => (
    <Box>
      <Box display="flex" alignItems="center">
        <FdTypography variant="body1">
          Techniques in the assessment
        </FdTypography>
        <FdTooltip title="Techniques refer to the specific methodologies or strategies required to resolve a challenge, such as Cipher Decryption, Image Analysis, etc.">
          <IconButton size="small" style={{ marginLeft: '5px' }}>
            <InfoOutlined />
          </IconButton>
        </FdTooltip>
      </Box>
      <FdTypography
        color="secondary"
        variant="captiontext1"
        style={{ marginBottom: '16px' }}
      >
        All the techniques in this assessment are listed below. The length of
        the bar represents the weightage of that technique in the assessment,
        and the number of segments on the bar represent the number of challenges
        the technique is a part of. You can hover over the bar segments to see
        the challenge name.
      </FdTypography>
      <FdSkeleton loading={loading} height="500px">
        <Box height="450px">
          <BarNivo
            data={assessmentChallengesTechniques}
            keys={tasksSelected.map((t) => t.name)}
            indexBy="technique"
            colors={['#35C3A1']}
            layout="horizontal"
            borderWidth={3}
            borderColor="#fff"
            tooltip={(d) => (
              <Box
                style={{
                  backgroundColor: '#777',
                  color: '#fff',
                  padding: '4px 8px',
                  fontSize: '12px',
                }}
              >
                {d.id}
              </Box>
            )}
            enableLabel={false}
          />
        </Box>
      </FdSkeleton>
    </Box>
  );

  const TechnologiesTab = () => (
    <Box>
      <Box display="flex" alignItems="center">
        <FdTypography variant="body1">
          Technologies in the assessment
        </FdTypography>
        <FdTooltip title="Technologies include the tools or environments incorporated within the challenge. They can be the environment itself, such as Windows, Linux, or Docker, or tools utilized within those environments, such as Splunk, Nessus, Python, etc. These technologies form a crucial part of the challenge, offering resources to navigate and solve the challenge effectively.">
          <IconButton size="small" style={{ marginLeft: '5px' }}>
            <InfoOutlined />
          </IconButton>
        </FdTooltip>
      </Box>
      <FdTypography
        color="secondary"
        variant="captiontext1"
        style={{ marginBottom: '16px' }}
      >
        All the technologies in this assessment are listed below. The length of
        the bar represents the weightage of that technology in the assessment,
        and the number of segments on the bar represent the number of challenges
        the technique is a part of. You can hover over the bar segments to see
        the challenge name.
      </FdTypography>
      <FdSkeleton loading={loading} height="500px">
        <Box height="450px">
          <BarNivo
            data={assessmentChallengesTechnologies}
            keys={tasksSelected.map((t) => t.name)}
            indexBy="technology"
            colors={['#35C3A1']}
            layout="horizontal"
            borderWidth={3}
            borderColor="#fff"
            tooltip={(d) => (
              <Box
                style={{
                  backgroundColor: '#777',
                  color: '#fff',
                  padding: '4px 8px',
                  fontSize: '12px',
                }}
              >
                {d.id}
              </Box>
            )}
            enableLabel={false}
          />
        </Box>
      </FdSkeleton>
    </Box>
  );

  return (
    <Box>
      <Summary
        loading={loading}
        data={[
          {
            value: 'Total Challenges',
            description: String(tasksSelected?.length),
          },
          {
            value: 'Duration',
            description: getDuration(hours, minutes),
          },
        ]}
        titleVariant="subtitle2"
        subtitleVariant="h4"
        justifyContent="center"
        titleColor="secondary"
        subtitleColor="primary"
      />
      <Box mt={2}>
        <FdCard variant="outlined">
          <Box display="flex">
            <Box display="flex" flexDirection="column" width="70%">
              <FdTypography variant="h4">
                What does this assessment cover?
              </FdTypography>
              <FdTypography
                variant="body2"
                color="secondary"
                style={{ margin: '16px 0' }}
              >
                This heatmap shows the skills that are being tested in this
                assessment in relation to the proficiency level. The X-Axis
                shows the skills and Y-axis represents the Proficiency level .
                Together, the blue-grey boxes show which skill of what
                proficiency has been covered in this assessment. Hover over the
                blue-grey boxes to see these details. You can also hover over
                the skills abbreviations on x-axis to see the skill name.
              </FdTypography>
              <FdSkeleton loading={loading} height="500px">
                <Box ref={divRef} height="100%">
                  <HeatmapNivo
                    customTopLegend={
                      <Box display="flex" alignItems="center">
                        <FdTypography variant="captiontext1">
                          Proficiency Levels
                        </FdTypography>
                        {Object.keys(PROFICIENCY).map((proficiency) => (
                          <Box p={1} display="flex" alignItems="center">
                            <FdTypography variant="captiontext1">{` ${PROFICIENCY[proficiency].level}:`}</FdTypography>
                            <Box
                              bgcolor={`${PROFICIENCY[proficiency].color}`}
                              className="rounded h-4 ml-1 px-1.5"
                            >
                              <FdTypography variant="captiontext1">
                                {proficiency}
                              </FdTypography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    }
                    customBottomLegend={
                      <HeatMapCustomLegend
                        width={width}
                        labelWidth={labelWidth}
                        specialtyLegends={specialtyLegends}
                      />
                    }
                    data={
                      skillsProficienciesData?.sort((a, b) => b.id - a.id) || []
                    }
                    yAxisLabel="Proficiency Levels"
                    tooltip={({ cell }) => {
                      return (
                        <Box
                          style={{
                            backgroundColor: '#777',
                            color: '#fff',
                            padding: '4px 8px',
                            fontSize: '12px',
                          }}
                        >
                          <FdTypography variant="captiontext2">
                            {cell.data?.skill}
                          </FdTypography>
                          <FdTypography variant="captiontext2">
                            {` Proficiency: ${cell.data?.proficiency}`}
                          </FdTypography>
                        </Box>
                      );
                    }}
                    color="#3E3EB7"
                  />
                </Box>
              </FdSkeleton>
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              style={{ margin: '20px' }}
            />
            <Box width="30%">
              <FdTab
                label={[
                  {
                    label: `Techniques(${assessmentChallengesTechniques?.length})`,
                    index: 0,
                    data: () => <TechniquesTab />,
                  },
                  {
                    label: `Technologies(${assessmentChallengesTechnologies?.length})`,
                    index: 1,
                    data: () => <TechnologiesTab />,
                  },
                ]}
              />
            </Box>
          </Box>
        </FdCard>
      </Box>
    </Box>
  );
};

AssessmentOverview.propTypes = {
  allTasks: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  specialtySkills: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

export default AssessmentOverview;
