const getStatusColor = (status) => {
  switch (status) {
    case 'Attempted':
    case 'In Progress':
      return 'action';
    case 'Completed':
      return 'success';
    case 'Incomplete':
    case 'Not Started':
      return 'warning';
    default:
      return 'success';
  }
};

export const getParticipantAssessmentStatusColor = (status) => {
  switch (status) {
    case 'Attempted':
      return 'error';
    case 'Started':
      return 'started';
    case 'Opened':
      return 'action';
    case 'Solved':
      return 'success';
    case 'Incomplete':
      return 'warning';
    default:
      return 'warning';
  }
};

export const getLabStatusColor = (status) => {
  switch (status) {
    case 'Not Started':
      return 'warning';
    case 'Starting':
      return 'primary';
    case 'Building':
      return 'primary';
    case 'Ready':
      return 'success';
    case 'Expired':
      return 'error';
    default:
      return 'warning';
  }
};

export const getLabTasksStatusColor = (status) => {
  switch (status) {
    case 'Defined':
      return 'success';
    case 'Undefined':
      return 'default';
    default:
      return 'default';
  }
};

export const getAssessmentStatusColor = (status) => {
  switch (status) {
    case 'In Progress':
      return 'action';
    case 'Finished':
    case 'Ended':
      return 'error';
    case 'Not Started':
      return 'warning';
    default:
      return 'success';
  }
};

export const getParticipantStatusColor = (status) => {
  const _status = status.replaceAll('_', ' ');
  switch (_status) {
    case 'STARTED':
    case 'IN PROGRESS':
      return 'action';
    case 'NOT STARTED':
      return 'warning';
    case 'FINISHED':
    case 'COMPLETED':
      return 'success';
    case 'REMOVED':
      return 'error';
    case 'NOT COMPLETED':
      return 'error';
    case 'INVITED':
      return 'primary';
    case 'ENDED':
      return 'error';
    default:
      return 'success';
  }
};
export default getStatusColor;

export const getComposeFileStatusColor = (status) => {
  switch (status) {
    case 'READY':
    case 'RUNNING':
    case 'APPROVED':
    case 'Build Successful':
      return 'success';
    case 'STOPPED':
    case 'STOPPING':
    case 'Stopping':
    case 'STARTING':
    case 'PROCESSING':
      return 'warning';
    case 'BUILDING':
    case 'Building':
      return 'action';
    case 'IN_QUEUE':
      return 'default';
    case 'FAILED':
    case 'DELETED':
    case 'REJECTED':
    case 'Build Failed':
      return 'error';
    default:
      return 'default';
  }
};
