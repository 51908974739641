import * as Yup from 'yup';
import { isValid as isDateValid } from 'date-fns';
import preMessageDefault from '../components/Assessment/preMessageDefault';
import { ASSESSMENT_TYPES } from '../constants';

export const initialValues = {
  assessmentType: {
    id: 'assessmentType',
    value: ASSESSMENT_TYPES.INDIVIDUAL_BASED,
  },
  name: '',
  description: '',
  startDateTime: null,
  endDateTime: null,
  jumpbox: false,
  hours: 0,
  minutes: 0,
  duration: '',
  taskIds: [],
  taskLabs: {},
  preMessage: preMessageDefault,
  postMessage:
    'Your assessment has now finished. You can contact your assessment organiser for information about what might occur next.',
  videoUrl: '',
};

Yup.addMethod(Yup.string, 'noWhitespace', function (message) {
  // eslint-disable-next-line react/no-this-in-sfc
  return this.test({
    name: 'noWhitespace',
    message,
    test: (value) => value && value.trim().length,
  });
});

export const validationSchema = Yup.object().shape(
  {
    assessmentType: Yup.object().shape({
      id: Yup.string(),
      value: Yup.string(),
    }),
    name: Yup.string()
      .required('Please enter an assessment name')
      .max(150, 'Assessment name must be 150 characters or less')
      .noWhitespace('Enter a valid assessment name'),
    description: Yup.string(),
    startDateTime: Yup.date().when(['endDateTime'], {
      is: (endDateTime) => endDateTime && isDateValid(endDateTime),
      then: Yup.date()
        .min(new Date(), 'Start Date and Time cannot be in the past')
        .max(
          Yup.ref('endDateTime'),
          'Start Date and Time should be before End Date and Time',
        )
        .nullable(),
      otherwise: Yup.date()
        .min(new Date(), 'Start Date and Time cannot be in the past')
        .nullable(),
    }),
    endDateTime: Yup.date().when(['startDateTime'], {
      is: (startDateTime) => startDateTime && isDateValid(startDateTime),
      then: Yup.date()
        .required('Please enter an End Date and Time')
        .typeError('Please enter an End Date and Time')
        .min(
          Yup.ref('startDateTime'),
          'The End Date and Time must occur after the Start Date and Time',
        ),
      otherwise: Yup.date()
        .required('Please enter an End Date and Time')
        .min(new Date(), 'End Date and Time cannot be in the past')
        .typeError('Please enter an End Date and Time'),
    }),
    hours: Yup.number()
      .min(0, 'Hours should be a whole number')
      .integer('Hours should be a whole number')
      .typeError('Hours should be a whole number'),
    minutes: Yup.number()
      .min(0, 'Minutes should be a whole number')
      .integer('Minutes should be a whole number')
      .typeError('Minutes should be a whole number'),
    taskIds: Yup.array().of(Yup.string()).min(1),
    preMessage: Yup.string()
      .required('Please enter a pre-assessment message')
      .noWhitespace('Please enter a valid pre-assessment message'),
    videoUrl: Yup.string()
      .nullable()
      .max(1500, 'Vimeo URL must be less than 1500 characters.'),
    postMessage: Yup.string()
      .required('Please enter a post-assessment message')
      .noWhitespace('Please enter a valid post-assessment message'),
    duration: Yup.string().when(['hours', 'minutes'], {
      is: (hours, minutes) => Number(hours) === 0 && Number(minutes) === 0,
      then: Yup.string().required('Both hours and minutes cannot be zero'),
      otherwise: Yup.string().nullable(),
    }),
    jumpbox: Yup.boolean().default(false),
    taskLabs: Yup.lazy((value) => {
      const schema = Object.keys(value).reduce((acc, curr) => {
        acc[curr] = Yup.string().trim().required();
        return acc;
      }, {});

      return Yup.object().shape(schema);
    }),
  },
  [['startDateTime', 'endDateTime']],
);
