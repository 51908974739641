import React, { useEffect, useContext } from 'react';
import { Redirect, useHistory, Route } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Auth } from 'aws-amplify';
import {
  FdProgress,
  AuthContext,
  globalStore,
  useSnapshot,
} from '@fifthdomain/fe-shared';
import { listOnboardingsByUserId } from '../graphql/queries';
import Survey from '../pages/Survey';

const SurveyRoute = ({ ...rest }) => {
  const { user, setUser } = useContext(AuthContext);
  const history = useHistory();
  const globalSnap = useSnapshot(globalStore);
  const assessmentId = rest?.computedMatch.params?.assessmentId;
  const sharedLinkRoute =
    assessmentId && rest?.path === '/assessor/survey/assess/:assessmentId';

  const { data: onboardingData, loading: onboardingDataLoading } = useQuery(
    gql(listOnboardingsByUserId),
    {
      variables: {
        userId: user?.username,
      },
      skip: !user,
    },
  );

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(function (userData) {
        setUser(userData);
      })
      .catch((err) => {
        console.log('error: ', err);
        history.push('/');
      });
  }, []);

  if (globalSnap.loading || onboardingDataLoading) return <FdProgress />;

  return (
    <Route
      {...rest}
      render={(props) => {
        const type = globalSnap.userType;

        if (user && type === 'ADMIN') {
          return <Redirect to="/landing" />;
        }
        if (user && type === 'PARTICIPANT') {
          if (sharedLinkRoute) {
            return <Survey {...rest} {...props} />;
          }
          const isOnboardingComplete =
            !!onboardingData?.listOnboardingsByUserId?.items.length;
          // if survey already done, then redirect to homepage
          if (isOnboardingComplete) {
            return <Redirect to="/landing/landing-homepage" />;
          }
          return <Survey {...rest} {...props} />;
        }
        return null;
      }}
    />
  );
};

export default SurveyRoute;
