import React, { useState } from 'react';
import { Box, Grid, useTheme } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import { gql } from '@apollo/client';
import ShowMoreText from 'react-show-more-text';
import PropTypes from 'prop-types';
import {
  FdButton,
  BasePage,
  FdTextField,
  FdLoadingSpinner,
  FdSelect,
  FdIcons,
  FdMarkdownRender,
  FdCard,
  FdTypography,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdChip,
} from '@fifthdomain/fe-shared';
import NoTemplateSelected from '../shared/images/no-template-date.svg';
import { listTemplates, listSpecialties } from '../graphql/queries';
import scrollToTop from '../shared/utils/scroll';
import { AssessmentTemplate } from '../components/Template';
import { getProficiencyLevel } from '../shared/utils/difficultyMapping';
import { PROFICIENCY } from '../constants';
import { listSkills } from '../queries/customQueries';

const CreateAssessmentTemplate = () => {
  const theme = useTheme();
  const globalSnap = useSnapshot(globalStore);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [text, setText] = useState('');
  const { Search } = FdIcons;
  const [selectedSkills, setSelectedSkills] = useState([]);
  const { data: specialtiesData } = useQueryRecursive(gql(listSpecialties));
  const { data: listSkillsData } = useQueryRecursive(gql(listSkills));

  const skillsList =
    specialtiesData?.listSpecialties?.items
      ?.map((skill) => skill?.skills?.items?.map((sk) => sk?.name))
      .flat() || [];

  const { data: listTemplatesData, loading: listTemplatesLoading } =
    useQueryRecursive(gql(listTemplates), {
      variables: {
        filter: {
          status: { eq: 'RELEASED' },
          participantEventType: { eq: 'ASSESSMENT' },
        },
      },
      fetchPolicy: 'cache-and-network',
      skip: !globalSnap.orgId,
      onCompleted: () => scrollToTop(),
    });

  const specialtySkillsData = listSkillsData?.listSkills?.items || [];

  const templateList = listTemplatesData?.listTemplates?.items.map((task) => {
    const tasks = task?.tasks?.items;
    const proficiency = tasks?.map((t) =>
      getProficiencyLevel(t?.task?.difficulty),
    );

    const proficiencyPercentages = [...new Set(proficiency)]?.map((p) => {
      return {
        name: p,
        percentage: (
          (tasks.filter((t) => getProficiencyLevel(t?.task?.difficulty) === p)
            .length /
            tasks?.length) *
          100
        ).toFixed(),
      };
    });

    const professionalSpeciality = [
      ...new Set(tasks?.map((t) => t?.task?.specialty?.name)),
    ];

    const skills = [
      ...new Set(
        tasks
          ?.map((t) => t?.task?.skills?.items?.map((sk) => sk?.skill?.name))
          .flat(),
      ),
    ];

    const techniques = [
      ...new Set(
        [
          ...new Set(
            tasks
              ?.map((t) =>
                t?.task?.skills?.items?.map((sk) =>
                  sk?.techniqueTags?.items?.map((tt) => tt?.techniqueTag?.name),
                ),
              )
              .flat(),
          ),
        ].flat(),
      ),
    ];

    const technologies = [
      ...new Set(
        tasks
          ?.map((t) =>
            t?.task?.technologyTags?.items?.map(
              (tt) => tt?.technologyTag?.name,
            ),
          )
          .flat(),
      ),
    ];

    return {
      ...task,
      name: task.name,
      tasks: task?.tasks,
      assessmentType: task?.teamBased ? 'Team' : 'Individual',
      duration: `${task.hours}h ${task?.minutes}m`,
      description: task?.description,
      proficiency: proficiencyPercentages,
      professionalSpeciality,
      skills,
      techniques,
      technologies,
    };
  });

  const ContentWithLabel = ({ label, content }) => (
    <Box display="flex" mb={1}>
      <FdTypography
        variant="body2"
        data-cy="task-drawer-label"
        style={{ marginRight: '16px', width: '80px' }}
      >
        {label}
      </FdTypography>
      <FdTypography variant="body2">{content}</FdTypography>
    </Box>
  );

  ContentWithLabel.propTypes = {
    label: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  };

  const searchResults = templateList
    ?.filter((templist) =>
      JSON.stringify(templist)?.toLowerCase()?.includes(text?.toLowerCase()),
    )
    ?.map((item) => ({
      ...item,
    }));

  const skillsFilteredResults =
    selectedSkills.length > 0
      ? searchResults?.filter((item) => {
          return item?.skills?.some((element) => {
            return selectedSkills.includes(element);
          });
        })
      : searchResults;

  const showNumber = (nameArray) => {
    return nameArray.length > 3
      ? `${nameArray[0]}, ${nameArray[1]}, ${nameArray[2]} +${
          nameArray.length - 3
        }`
      : nameArray?.join(', ');
  };

  return (
    <BasePage>
      <Grid container spacing={2} style={{ paddingTop: '5px' }}>
        <Grid item xs={4}>
          <FdTypography variant="subtitle1">
            Select a template from this space
          </FdTypography>
          <Box my={2}>
            <FdTextField
              id="search-text-field"
              placeholder="Search by a keyword"
              variant="standard"
              value={text}
              startAdornment={<Search color="inherit" />}
              onChange={(e) => setText(e.target.value)}
              fullWidth
            />
          </Box>
          <FdSelect
            id="selectedSkills"
            placeholder="Select from list of skills to get templates recommended"
            options={skillsList}
            onChange={(values) => {
              setSelectedSkills(values);
            }}
            width="100%"
            multiple
          />
          <Box
            mt={2}
            mb={1}
            style={{
              height: '100vh',
              overflowY: 'scroll',
            }}
          >
            <FdTypography variant="body1" color="secondary">
              {`Displaying all ${templateList?.length} templates`}
            </FdTypography>

            {listTemplatesLoading ? (
              <FdLoadingSpinner />
            ) : (
              skillsFilteredResults?.map((t) => {
                return (
                  <FdCard
                    variant="outlined"
                    heading={
                      <Box display="flex" justifyContent="space-between">
                        <Box>
                          <FdTypography variant="subtitle1">
                            {t.name}
                          </FdTypography>
                          <FdTypography color="secondary" variant="subtitle2">
                            {`${t?.assessmentType} Assessment`}
                          </FdTypography>
                        </Box>
                        <FdButton
                          variant="secondary"
                          size="small"
                          onClick={() => setSelectedTemplate(t)}
                        >
                          Preview
                        </FdButton>
                      </Box>
                    }
                    style={{
                      borderLeft:
                        selectedTemplate?.name === t?.name
                          ? `4px solid ${theme.palette.primary.main}`
                          : 'none',
                    }}
                    data-cy="details"
                  >
                    <Box>
                      <Box mb={1}>
                        <FdTypography variant="body2" color="secondary">
                          <ShowMoreText
                            more="See more"
                            less="See less"
                            anchorClass="see-more"
                          >
                            <FdMarkdownRender markdown={t?.description} />
                          </ShowMoreText>
                        </FdTypography>
                      </Box>
                      <ContentWithLabel
                        label="Duration"
                        content={
                          <FdTypography
                            color="secondary"
                            variant="body2"
                            data-cy="specialty"
                          >
                            {t?.duration}
                          </FdTypography>
                        }
                      />
                      <ContentWithLabel
                        label="Proficiency "
                        content={
                          <FdTypography
                            color="secondary"
                            variant="body2"
                            data-cy="specialty"
                          >
                            {t?.proficiency?.map((item) => {
                              return (
                                <Box display="flex" mb={1}>
                                  <FdChip
                                    style={{
                                      backgroundColor:
                                        PROFICIENCY[item?.name]?.color || '',
                                      marginRight: '4px',
                                    }}
                                    size="small"
                                    label={item?.name || ''}
                                    rounded={false}
                                  />
                                  {`${item?.percentage}%`}
                                </Box>
                              );
                            })}
                          </FdTypography>
                        }
                      />
                      <ContentWithLabel
                        label="Professional Specialty"
                        content={
                          <FdTypography
                            color="secondary"
                            variant="body2"
                            data-cy="specialty"
                          >
                            {t?.professionalSpeciality?.join(', ')}
                          </FdTypography>
                        }
                      />
                      <ContentWithLabel
                        label="Skills"
                        content={
                          <FdTypography
                            variant="body2"
                            color="secondary"
                            data-cy="speciality"
                          >
                            {showNumber(t?.skills)}
                          </FdTypography>
                        }
                      />
                      <ContentWithLabel
                        label="Techniques "
                        content={
                          <FdTypography
                            color="secondary"
                            variant="body2"
                            data-cy="specialty"
                          >
                            {showNumber(t?.techniques)}
                          </FdTypography>
                        }
                      />
                      <ContentWithLabel
                        label="Technologies "
                        content={
                          <FdTypography
                            color="secondary"
                            variant="body2"
                            data-cy="specialty"
                          >
                            {showNumber(t?.technologies)}
                          </FdTypography>
                        }
                      />
                    </Box>
                  </FdCard>
                );
              })
            )}
          </Box>
        </Grid>
        <Grid item xs={8}>
          {!listTemplatesLoading && templateList && selectedTemplate ? (
            <AssessmentTemplate
              selectedTemplate={selectedTemplate}
              specialtySkills={specialtySkillsData}
            />
          ) : (
            <Box
              height="740px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <FdTypography variant="h4" color="secondary">
                Select a template from the templates panel on the left to view
                its comprehensive details here
              </FdTypography>
              <img
                src={NoTemplateSelected}
                alt="no-content-data"
                className="mt-20"
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default CreateAssessmentTemplate;
