import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdTable } from '@fifthdomain/fe-shared';
import { getDifficultyLabel } from '../../shared/utils/difficultyMapping';
import TaskInfoCell from '../Assessment/TaskInfoCell';
import TableHeaderColumnWithTooltip from '../Assessment/TableHeaderColumnWithTooltip';

import {
  getCommaSeparatedPlusSuffix,
  upperCaseFirstLetter,
} from '../../shared/utils/stringUtils';

const AssessmentTemplateTable = ({ actions, rows }) => {
  const columns = [
    {
      field: 'name',
      width: 300,
      headerName: 'Name',
      valueGetter: (params) => params.row.name,
      renderCell: (params) => <TaskInfoCell values={params.row} />,
    },
    {
      field: 'specialtyName',
      flex: 1,
      headerName: 'Professional Specialty',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Professional Specialty"
          tooltipText="Professional specialties represent broad categories of commonly found technical functions within the field of cyber operations. These specialties offer a structured framework under which a particular challenge can be classified."
        />
      ),
    },
    {
      field: 'skills',
      flex: 1,
      headerName: 'Skills',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Skills"
          tooltipText="Skills indicate the specific, acquired ability necessary to solve a challenge within a given timeframe or effort."
        />
      ),
      valueGetter: (params) =>
        params?.value?.length > 0 ? [...params?.value]?.join(' ') : '',
      renderCell: (params) => getCommaSeparatedPlusSuffix(params?.row?.skills),
    },
    {
      field: 'type',
      width: 100,
      headerName: 'Type',
      renderCell: (params) => upperCaseFirstLetter(params?.value),
    },
  ];

  return (
    <Box height="450px" data-cy="tasks-table" mt={1}>
      <FdTable
        defaultMuiToolbarSettings={{
          showMuiDefaultToolbar: true,
          columnsButton: true,
          filterButton: true,
          densityButton: true,
        }}
        slotProps={{
          columnsPanel: {
            getTogglableColumns: () => [
              'creator',
              'specialtyName',
              'skills',
              'type',
              'tags',
              'recommendedPoints',
              'technologyTags',
              'techniqueTags',
            ],
            disableHideAllButton: true,
            disableShowAllButton: true,
          },
        }}
        toolbarSettings={{
          title: 'Challenges',

          filterOptions: [],
          searchBox: true,
          filterButton: true,
        }}
        actions={actions}
        rows={
          rows?.map((task) => {
            return {
              ...task,
              difficulty: getDifficultyLabel(task.difficulty),
            };
          }) || []
        }
        columns={columns}
        pagination
        visibleSelection
        rowsPerPageOptions={[5, 10, 20]}
        tablePageSize={5}
        gridId="assessor-assessment-templates"
      />
    </Box>
  );
};

AssessmentTemplateTable.defaultProps = {
  actions: [],
};

AssessmentTemplateTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};

export default AssessmentTemplateTable;
