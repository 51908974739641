import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { FdIconsV5 } from '@fifthdomain/fe-shared';
import { SPECIALTY_COLOR } from '../../constants';

const useStyles = makeStyles(() => ({
  avatar: {
    color: '#FFFFFF',
    height: '2rem',
    width: '2rem',
    borderRadius: '4px',
    padding: '5px',
  },
}));

const SpecialtyIcon = ({ specialty }) => {
  const classes = useStyles();
  const { LocationOn, Memory, LockOpen, Code, Router, Build } = FdIconsV5;
  const SPECIALTY_ICON = {
    Intelligence: Memory,
    Protection: LockOpen,
    Detection: LocationOn,
    Investigation: Code,
    Penetration: Router,
    Engineering: Build,
  };
  const color = SPECIALTY_COLOR[specialty] || '#78909C';
  const Icon = SPECIALTY_ICON[specialty] || LocationOn;

  return (
    <Icon
      className={classes.avatar}
      style={{
        backgroundColor: color,
        fontSize: 38,
      }}
    />
  );
};

SpecialtyIcon.propTypes = {
  specialty: PropTypes.string.isRequired,
};

export default SpecialtyIcon;
