import { isAfter, isBefore } from 'date-fns';

export const getParticipantStatus = (status) => {
  const _status = status.replaceAll('_', ' ');
  switch (_status) {
    case 'STARTED':
      return 'In Progress';
    case 'NOT STARTED':
      return 'Not Started';
    case 'FINISHED':
      return 'Completed';
    case 'REMOVED':
      return 'Removed';
    case 'INVITED':
      return 'Invited';
    case 'NOT COMPLETED':
      return 'Not Completed';
    case 'ENDED':
      return 'Ended';
    default:
      return 'Completed';
  }
};
export const getCompetitionStatus = (
  startDateTime,
  endDateTime,
  serverTime,
) => {
  if (
    !startDateTime ||
    isAfter(new Date(startDateTime || undefined), new Date(serverTime))
  ) {
    return 'NOT_STARTED';
  }
  if (isBefore(new Date(endDateTime || undefined), new Date(serverTime))) {
    return 'ENDED';
  }
  if (isBefore(new Date(startDateTime || undefined), new Date(serverTime))) {
    return 'STARTED';
  }
  if (!serverTime) {
    return 'STARTED';
  }
  return 'ENDED';
};
