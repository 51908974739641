import convertChartToImage from '../../../shared/utils/convertChartToImage';

export const captureChartImage = async (setChartImage, chartId) => {
  try {
    const image = await convertChartToImage(chartId);
    setChartImage(image);
  } catch (error) {
    console.log('Error: generating image from chart', error);
  }
};
