import React, { useContext, useState } from 'react';
import { Grid, Box } from '@material-ui/core';
import singleSpa from 'single-spa';
import { useParams } from 'react-router';
import {
  gql,
  useMutation,
  useQuery,
  useSubscription,
  useLazyQuery,
} from '@apollo/client';
import { isWithinInterval } from 'date-fns';
import {
  BasePage,
  FdCard,
  FdTypography,
  FdButton,
  FdModal,
  FdLoadingSpinner,
  AuthContext,
} from '@fifthdomain/fe-shared';
import AssessmentRules from './AssessmentRules';
import {
  startAssessmentForUser,
  startGroupAssessment,
  updateUserAssessment,
} from '../graphql/mutations';
import { onStartGroupAssessment } from '../graphql/subscriptions';
import {
  getDateTimeZoneFormatted,
  getFormattedDuration,
} from '../shared/utils/dateUtils';
import { successToastMessage } from '../shared/utils/toast';
import { getUserAssessment } from '../queries/customQueries';
import { getSystemTime, downloadVPNConfigureFile } from '../graphql/queries';

const AssessmentStart = () => {
  const { user } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const { assessmentId } = useParams();
  const { data: assessmentData, loading: assessmentLoading } = useQuery(
    gql(getUserAssessment),
    {
      variables: {
        id: assessmentId,
      },
      skip: !assessmentId,
      fetchPolicy: 'cache-and-network',
    },
  );

  const [downloadVPNConfig, { loading: downloadVPNConfigInProgress }] =
    useLazyQuery(gql(downloadVPNConfigureFile));
  const { data: serverTime, loading: serverTimeLoading } = useQuery(
    gql(getSystemTime),
    {
      fetchPolicy: 'network-only',
    },
  );

  const assessmentDataSelected = assessmentData?.getUserAssessment;
  const [updateUserAssessmentMutation] = useMutation(gql(updateUserAssessment));

  const [startAssessment, { loading: startAssessmentInProgress }] = useMutation(
    gql(startAssessmentForUser),
    {
      refetchQueries: ['ListUserAssessments'],
      awaitRefetchQueries: true,
      onCompleted: (_data) => {
        singleSpa.navigateToUrl(
          `/assessor/assessment-tasks/${_data?.startAssessmentForUser}`,
        );
        return null;
      },
      onError: () => singleSpa.navigateToUrl('/assessor/error'),
    },
  );

  const [
    startGroupAssessmentMutation,
    { loading: startGroupAssessmentProgress },
  ] = useMutation(gql(startGroupAssessment), {
    refetchQueries: ['ListUserAssessments'],
    awaitRefetchQueries: true,
    onCompleted: (_data) => {
      singleSpa.navigateToUrl(`/assessor/assessment-tasks/${assessmentId}`);
      return null;
    },
    onError: () => singleSpa.navigateToUrl('/assessor/error'),
  });

  useSubscription(gql(onStartGroupAssessment), {
    variables: {
      groupId: assessmentDataSelected?.groupId,
      assessmentId: assessmentDataSelected?.userAssessmentAssessmentId,
    },
    onSubscriptionData: () => {
      successToastMessage('Assessment has started');
      singleSpa.navigateToUrl(`/assessor/assessment-tasks/${assessmentId}`);
    },
    fetchPolicy: 'cache-and-network',
    skip: !assessmentDataSelected,
  });

  if (
    assessmentLoading ||
    startAssessmentInProgress ||
    startGroupAssessmentProgress ||
    !assessmentDataSelected ||
    serverTimeLoading ||
    downloadVPNConfigInProgress
  ) {
    return <FdLoadingSpinner />;
  }

  const {
    assessment: {
      name,
      startDateTime,
      endDateTime,
      hours,
      minutes,
      preMessage,
      videoUrl,
    },
    id: userAssessmentId,
  } = assessmentDataSelected;

  const startDate = getDateTimeZoneFormatted(startDateTime); // TimeZone, like AEDT
  const endDate = getDateTimeZoneFormatted(endDateTime); // TimeZone, like AEDT

  const readyToStart = isWithinInterval(new Date(serverTime?.getSystemTime), {
    start: new Date(startDateTime),
    end: new Date(endDateTime),
  });

  const enterAssessment = () => {
    if (!assessmentDataSelected?.assessment?.teamBased) {
      startAssessment({
        variables: {
          userAssessmentId,
        },
      });
      updateUserAssessmentMutation({
        variables: {
          input: {
            id: assessmentDataSelected?.id,
            lastActive: new Date().toISOString(),
          },
        },
      });
    } else if (assessmentDataSelected?.status !== 'STARTED') {
      setShowModal(true);
    } else {
      singleSpa.navigateToUrl(`/assessor/assessment-tasks/${assessmentId}`);
    }
  };

  return (
    <BasePage heading={name} data-cy="assessment-start-page">
      <FdModal
        size="md"
        title="Are you sure you want to start?"
        description="This will start the assessment for all members of your team. You will not be able to pause the assessment once it starts"
        confirm="START"
        dismiss="CANCEL"
        open={showModal}
        onConfirm={() => {
          startGroupAssessmentMutation({
            variables: {
              groupId: assessmentDataSelected?.groupId,
              assessmentId: assessmentDataSelected?.userAssessmentAssessmentId,
            },
          });
          updateUserAssessmentMutation({
            variables: {
              input: {
                id: assessmentDataSelected?.id,
                lastActive: new Date().toISOString(),
              },
            },
          });
        }}
        onDismiss={() => {
          setShowModal(false);
        }}
        data-cy="start-modal"
      />
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <AssessmentRules
            videoUrl={videoUrl}
            userFullName={user?.attributes?.name}
            preMessage={preMessage}
            assessmentData={assessmentDataSelected}
          />
        </Grid>
        <Grid item xs={3}>
          <FdCard>
            <Box>
              <FdTypography variant="h4" data-cy="available-heading">
                Available from
              </FdTypography>
              <FdTypography variant="subtitle1" data-cy="start-date">
                {startDate}
              </FdTypography>
            </Box>
            <Box mt={2}>
              <FdTypography variant="h4" data-cy="available-until-heading">
                Available until
              </FdTypography>
              <FdTypography variant="subtitle1" data-cy="end-date">
                {endDate}
              </FdTypography>
            </Box>
            <Box mt={2}>
              <FdTypography variant="h4" data-cy="duration-title">
                Assessment duration
              </FdTypography>
              <FdTypography variant="subtitle1" data-cy="duration-value">
                {getFormattedDuration(hours, minutes)}
              </FdTypography>
            </Box>
            <Box mt={3}>
              <FdTypography variant="subtitle1" data-cy="welcome-message">
                {readyToStart && assessmentDataSelected?.status !== 'STARTED'
                  ? 'Your assessment is ready to begin. Once you click on the start assessment button below the assessment will commence and the timer will start.'
                  : readyToStart &&
                    assessmentDataSelected?.status === 'STARTED' &&
                    assessmentDataSelected?.assessment?.teamBased
                  ? 'Your team member has started the assessment. You can click the Enter Assessment button below to join the assessment.'
                  : readyToStart &&
                    assessmentDataSelected?.status === 'STARTED' &&
                    !assessmentDataSelected?.assessment?.teamBased
                  ? singleSpa.navigateToUrl(
                      `/assessor/assessment-tasks/${assessmentId}`,
                    )
                  : 'The assessment has not yet started. You can start the assessment at any time within the time period listed above.'}
              </FdTypography>
              <Box mt={3}>
                <FdButton
                  size="large"
                  style={{ width: '100%' }}
                  disabled={!readyToStart}
                  onClick={async () => {
                    if (
                      assessmentDataSelected?.assessment?.enableVPN === 'TRUE'
                    ) {
                      const orgId = assessmentDataSelected?.user?.orgId;
                      await downloadVPNConfig({
                        variables: {
                          userAssessmentId: assessmentDataSelected?.id,
                          assessmentId: assessmentDataSelected?.assessment?.id,
                          orgId,
                        },
                        onCompleted: () => {
                          enterAssessment();
                        },
                      });
                    } else {
                      enterAssessment();
                    }
                  }}
                  data-cy="start-button"
                >
                  {assessmentDataSelected?.status === 'STARTED'
                    ? 'Enter Assessment'
                    : ' Start Assessment'}
                </FdButton>
              </Box>
            </Box>
          </FdCard>
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default AssessmentStart;
