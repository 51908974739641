import React, { useEffect, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import singleSpa from 'single-spa';
import {
  AuthContext,
  Authorization,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { user, setUser } = useContext(AuthContext);
  const globalSnap = useSnapshot(globalStore);

  // const { data: orgProductsData, loading: orgProductsLoading } = useQuery(
  //   gql(listProductsByOrgId),
  //   {
  //     variables: {
  //       orgId: userData?.getUserById?.items?.[0].orgId,
  //     },
  //     skip: !userData,
  //   },
  // );

  // const orgProducts = orgProductsData?.listProductsByOrgId?.items?.map(
  //   (orgProduct) => orgProduct?.product?.name,
  // );

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((authUser) => {
        setUser(authUser);
      })
      .catch((err) => {
        console.log('error: ', err);
        singleSpa.navigateToUrl('/');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          const allowedManagerPermissions =
            Authorization.canManageEvents(globalSnap?.permissions) ||
            Authorization.canViewInsights(globalSnap?.permissions);
          const type = globalSnap?.userType;
          if (
            type === 'ADMIN' ||
            (type === 'MANAGER' && allowedManagerPermissions)
          ) {
            // TODO: will remove the comment , when dashboards is moved to new microfrontends
            // if (!orgProducts?.includes('Assessor'))
            //   singleSpa.navigateToUrl('/user-management');
            return <Component {...rest} {...props} />;
          }
          if (type === 'MANAGER' && !allowedManagerPermissions) {
            singleSpa.navigateToUrl('/user-management');
            return null;
          }
          if (type === 'PARTICIPANT') {
            return <Redirect to="/assessor/survey" />;
          }
        }
        return null;
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default ProtectedRoute;
