import React, { useState } from 'react';
import { Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  FdTypography,
  FdIconsV5,
  FdLoadingSpinner,
} from '@fifthdomain/fe-shared';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  createChallengeRating,
  updateChallengeRating,
} from '../../graphql/mutations';
import { listChallengesRatingsByUserAssessmentId } from '../../graphql/queries';

const ChallengeRating = ({
  userId,
  taskId,
  userAssessmentId,
  assessmentId,
}) => {
  const [selectedRating, setSelectedRating] = useState(null);
  const [hoverRating, setHoverRating] = useState(null);
  const [ratingId, setRatingId] = useState(null);

  const { Star, StarBorder } = FdIconsV5;
  const theme = useTheme();

  const { loading: ratingsLoading } = useQuery(
    gql(listChallengesRatingsByUserAssessmentId),
    {
      variables: {
        userAssessmentId,
        taskId: { eq: taskId },
      },
      skip: !userAssessmentId || !taskId,
      onCompleted: (data) => {
        setRatingId(
          data?.listChallengesRatingsByUserAssessmentId?.items?.[0]?.id,
        );
        setSelectedRating(
          data?.listChallengesRatingsByUserAssessmentId?.items?.[0]?.ratings,
        );
      },
    },
  );

  const [createChallengeRatingMutation] = useMutation(
    gql(createChallengeRating),
  );

  const [updateChallengeRatingMutation] = useMutation(
    gql(updateChallengeRating),
  );

  const renderStar = (currentRating) => {
    const isFilled = currentRating <= (hoverRating || selectedRating);

    const commonProps = {
      key: currentRating,
      style: { color: theme.palette.primary.main, cursor: 'pointer' },
      onClick: () => {
        setSelectedRating(currentRating);
        if (!ratingId) {
          createChallengeRatingMutation({
            variables: {
              input: {
                userId,
                assessmentId,
                taskId,
                userAssessmentId,
                ratings: currentRating,
              },
            },
            onCompleted: (data) => {
              setRatingId(data?.createChallengeRating?.id);
            },
          });
        } else if (ratingId && selectedRating !== currentRating) {
          updateChallengeRatingMutation({
            variables: {
              input: {
                id: ratingId,
                ratings: currentRating,
              },
            },
          });
        }
      },
      onMouseEnter: () => setHoverRating(currentRating),
      onMouseLeave: () => setHoverRating(null),
    };

    return isFilled ? (
      <Star {...commonProps} />
    ) : (
      <StarBorder {...commonProps} />
    );
  };

  return (
    <Box className="flex flex-col text-center border-t border-gray-300 py-4 my-4 space-y-2">
      <FdTypography variant="subtitle1">Rate this challenge!</FdTypography>
      <FdTypography variant="body1" color="secondary">
        Your feedback helps us improve in the future.
      </FdTypography>
      <Box className="flex justify-center items-center space-x-1">
        {ratingsLoading ? (
          <FdLoadingSpinner />
        ) : (
          [...Array(5)].map((_, index) => renderStar(index + 1))
        )}
      </Box>
    </Box>
  );
};

ChallengeRating.propTypes = {
  userId: PropTypes.string.isRequired,
  assessmentId: PropTypes.string.isRequired,
  userAssessmentId: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
};

export default ChallengeRating;
