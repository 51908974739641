import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { getDateTimeZoneCustom } from '../../shared/utils/dateUtils';

const Timeline = ({
  data,
  colors,
  tooltipFunc,
  startAssessmentTime,
  endAssessmentTime,
  chartId,
  enableAnimations,
}) => {
  const options = {
    series: data,
    options: {
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '50%',
          rangeBarGroupRows: true,
          endingShape: 'rounded',
        },
      },
      chart: {
        id: chartId,
        toolbar: {
          show: false,
        },
      },
      fill: {
        type: 'solid',
      },
      xaxis: {
        type: 'datetime',
        min: startAssessmentTime, // Timeline start  assessment date
        max: endAssessmentTime, // Timeline end assessment date
        labels: {
          formatter: (value) => getDateTimeZoneCustom(value, 'hh:mm a'),
        },
        position: 'top',
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            fontSize: '14px',
            fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
            fontWeight: 600,
          },
        },
      },
      colors,
      legend: {
        show: false,
      },
      tooltip: {
        custom: tooltipFunc,
      },
      animations: {
        enabled: enableAnimations,
      },
    },
  };

  return (
    <Box data-cy={`timeline-chart-${chartId}`}>
      <Chart
        options={options.options}
        series={options.series}
        type="rangeBar"
        width="100%"
        height="340"
      />
    </Box>
  );
};

Timeline.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.string,
          y: PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
          ),
        }),
      ),
    }),
  ),
  colors: PropTypes.arrayOf(PropTypes.string),
  tooltipFunc: PropTypes.func,
  startAssessmentTime: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number,
  ]),
  endAssessmentTime: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number,
  ]),
  chartId: PropTypes.string,
  enableAnimations: PropTypes.bool,
};

Timeline.defaultProps = {
  data: [],
  colors: [],
  tooltipFunc: undefined,
  startAssessmentTime: undefined,
  endAssessmentTime: undefined,
  chartId: 'timeline-chart',
  enableAnimations: true,
};

export default Timeline;
