import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import singleSpa from 'single-spa';
import {
  BasePage,
  FdLoadingSpinner,
  AuthContext,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import AssessmentRules from './AssessmentRules';
import AssessmentSummary from '../components/Participant/AssessmentSummary';
import useGetAssessmentsTasks from '../hooks/useGetAssessmentsTasks';
import {
  getTasksAttempts,
  getTasksAttemptsByAssessmentId,
} from '../queries/customQueries';
import { listGroupMembersByGroupId } from '../graphql/queries';
import useStopAssessment from '../hooks/useStopAssessment';
import { getAssessmentFinishDateTime } from '../shared/utils/dateUtils';

const AssessmentOverview = () => {
  const { user } = useContext(AuthContext);
  const { assessmentId } = useParams();
  const { data: assessmentTasksData, loading: assessmentTasksLoading } =
    useGetAssessmentsTasks({ userId: user?.username });
  // get the assessment selected
  const assessmentDataFiltered =
    assessmentTasksData?.listUserAssessments?.items.find(
      (ad) => ad.id === assessmentId,
    );
  const teamBased = assessmentDataFiltered?.assessment?.teamBased;
  // get all task attempts for the user assessment
  const { data: tasksAttemptsUserData, loading: tasksAttemptsUserDataLoading } =
    useQueryRecursive(gql(getTasksAttempts), {
      variables: {
        taskAttemptUserAssessmentId: assessmentDataFiltered?.id,
      },
      skip: !assessmentDataFiltered?.id,
    });

  // get all task attempts for the assessment
  const {
    data: tasksAttemptsAssessmentData,
    loading: tasksAttemptsAssessmentDataLoading,
  } = useQueryRecursive(gql(getTasksAttemptsByAssessmentId), {
    variables: {
      assessmentId: assessmentDataFiltered?.assessment?.id,
      limit: 1000,
    },
    skip: !assessmentDataFiltered?.assessment?.id,
  });

  const { data: groupMembers, loading: groupMembersLoading } = useQuery(
    gql(listGroupMembersByGroupId),
    {
      variables: {
        groupId: assessmentDataFiltered?.groupId,
      },
      skip: !assessmentDataFiltered && teamBased,
    },
  );

  const [stopAssessment, { loading: stopAssessmentInProgress }] =
    useStopAssessment();

  if (
    assessmentTasksLoading ||
    tasksAttemptsUserDataLoading ||
    tasksAttemptsAssessmentDataLoading ||
    stopAssessmentInProgress ||
    !assessmentDataFiltered ||
    groupMembersLoading
  ) {
    return <FdLoadingSpinner />;
  }

  const teamMembers =
    groupMembers?.listGroupMembersByGroupId?.map((member) => member.id) || [];

  // redirect to home page if not started
  if (assessmentTasksData && assessmentDataFiltered?.status !== 'STARTED') {
    singleSpa.navigateToUrl('/landing/landing-homepage');
  }

  const tasksAttemptsData =
    (assessmentDataFiltered?.assessment?.teamBased
      ? tasksAttemptsAssessmentData?.listTaskAttempts?.items?.filter((ta) =>
          teamMembers?.includes(ta.userId),
        )
      : tasksAttemptsUserData?.listTaskAttempts?.items) || [];

  const {
    assessment: { name, hours, minutes },
    id: userAssessmentId,
    startedOn,
  } = assessmentDataFiltered;

  const tasksCompleted =
    tasksAttemptsData?.filter((t) => t.success).length || 0;

  return (
    <BasePage
      heading={name}
      data-cy="overview-page"
      breadCrumbs={[{ url: '/landing/landing-homepage', name: 'Home' }]}
      currentPageBreadcrumbLabel={name}
      linkComponent={RouterLink}
      renderBreadCrumbAsButton
    >
      <Box mb={2}>
        <AssessmentSummary
          assessment={{
            hours,
            minutes,
            endDateTime: getAssessmentFinishDateTime(startedOn, hours, minutes),
            tasksCompleted,
          }}
          onFinish={() =>
            stopAssessment({
              variables: {
                userAssessmentId,
              },
            })
          }
        />
      </Box>
      <AssessmentRules
        videoUrl={assessmentDataFiltered?.assessment?.videoUrl}
        userFullName={user?.attributes?.name}
        preMessage={assessmentDataFiltered?.assessment?.preMessage}
        assessmentData={assessmentDataFiltered}
      />
    </BasePage>
  );
};

export default AssessmentOverview;
