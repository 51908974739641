import { useQuery, gql } from '@apollo/client';
import { listUrls } from '../../../../queries/customQueries';

const useGetLogoImageKey = ({ orgId }) => {
  const { data: urlData } = useQuery(gql(listUrls), {
    variables: {
      filter: {
        orgId: { eq: orgId },
        theme: { eq: 'LIGHT' },
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const logoImageKey = urlData?.listUrls?.items?.[0]?.key;

  return { logoImageKey };
};

export default useGetLogoImageKey;
