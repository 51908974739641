import domtoimage from 'dom-to-image';

const convertChartToImage = async (chartId) => {
  const scale = 2;
  return new Promise((resolve, reject) => {
    const chartElement = document.getElementById(chartId);
    // Convert the chart container to an image
    domtoimage
      .toPng(chartElement, {
        height: chartElement?.offsetHeight * scale,
        style: {
          transform: `scale(${scale}) translate(${
            chartElement?.offsetWidth / 2 / scale
          }px, ${chartElement?.offsetHeight / 2 / scale}px)`,
        },
        width: chartElement?.offsetWidth * scale,
      })
      .then((dataUrl) => {
        resolve(dataUrl);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default convertChartToImage;
