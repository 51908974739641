import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdTable } from '@fifthdomain/fe-shared';

const TopFiveTable = ({ actions, columns, data, title }) => {
  // restrict to 5 rows
  const rows = data?.slice(0, 5);
  return (
    <Box
      mt={2}
      mb={2}
      height="390px"
      width="100%"
      style={{ backgroundColor: 'white' }}
      data-cy="top-five-table"
    >
      <FdTable
        toolbarSettings={{
          title,
          searchBox: false,
        }}
        disableSelectionOnClick
        actions={actions}
        rows={rows}
        columns={columns}
        visibleSelection
        tablePageSize={5}
        hideFooter
        noRowsMessage="No data to present"
        gridId="assessor-top-five-participants"
      />
    </Box>
  );
};

TopFiveTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      rank: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      points: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
  ).isRequired,
  title: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      width: PropTypes.number,
      headerName: PropTypes.string,
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TopFiveTable;
