/*
  // Example usage:
  const data = [2, 4, 4, 4, 5, 5, 7, 9];
  const standardDeviation = getStandardDeviation(data);
*/

const getStandardDeviation = (values) => {
  // Step 1: Calculate the mean
  const mean = values.reduce((acc, val) => acc + val, 0) / values.length;

  // Step 2: Calculate the squared differences
  const squaredDifferences = values.map((val) => (val - mean) ** 2);

  // Step 3: Calculate the average of the squared differences
  const averageSquaredDifference =
    squaredDifferences.reduce((acc, val) => acc + val, 0) / values.length;

  // Step 4: Take the square root of the average
  const standardDeviation = Math.sqrt(averageSquaredDifference);

  return standardDeviation;
};

export default getStandardDeviation;
