import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { styles } from './styles';

const PdfTableHeader = ({ columns }) => (
  <View style={styles.tableHeaderContainer}>
    {columns.map((c) => (
      <Text
        style={{
          width: c.width,
          textAlign: c.textAlign,
        }}
        key={c.name}
      >
        {c.label}
      </Text>
    ))}
  </View>
);

PdfTableHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
};

export default PdfTableHeader;
