import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, Document } from '@react-pdf/renderer';
import PdfTable from './PdfTable';
import LogoInlineColorSVG from '../../../resources/logo/fd-light-logo.png';
import LogoInlineOverlaySVG from '../../../resources/logo/logo-overlay.png';
import { getOrdinal } from '../../../shared/utils/stringUtils';
import { styles } from './styles';
import ProgressIndicatorPdf from './ProgressIndicatorPdf';
import ProficiencyApproachTablePdf from './ProficiencyApproachTablePdf';
import { PROFICIENCY } from '../../../constants';

const Footer = () => (
  <View style={styles.footer} fixed>
    <View style={styles.footerContainer}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text>Ω</Text>
        <Text style={{ marginLeft: 10, fontFamily: 'Montserrat' }}>
          Fifth Domain Pty Ltd
        </Text>
      </View>
      <Text
        style={{ fontFamily: 'Montserrat' }}
        render={({ pageNumber, totalPages }) =>
          `Page ${pageNumber}/${totalPages}`
        }
        fixed
      />
    </View>
  </View>
);

const InsightsPdfReport = ({
  userName,
  assessmentName,
  insightsData,
  logoUrl,
  techniqueChartImage,
  technologyChartImage,
  performanceSkillsChartImage,
  performanceTriangleChartImage,
  heatmapChartImage,
}) => {
  const {
    submittedDate,
    skills,
    rank,
    totalCandidates,
    lineChartRows,
    assessmentScore,
    success,
    efficiency,
    speed,
    allTaskAttemptsUserWise,
    taskAttemptsDetail,
    startDateTime,
    taskNotes,
    tasks,
    completedTasks,
    selectedUserIds,
    teamBased,
    tasksOpened,
  } = insightsData ?? {};
  const skillTableColumns = [
    {
      name: 'skillName',
      label: 'Skill Name',
      width: '40%',
      textAlign: 'left',
    },
    {
      name: 'skillScore',
      label: 'Participant Skill score',
      width: '30%',
      textAlign: 'center',
    },
    {
      name: 'cohortAverage',
      label: 'Cohort Average',
      width: '30%',
      textAlign: 'center',
    },
  ];
  const teamOrParticipant = teamBased ? 'team' : 'participant';

  return (
    <Document>
      <Page size="A4" style={styles.pageA4}>
        {logoUrl ? (
          <View style={styles.headerRow}>
            <View style={styles.customLogoContainer}>
              <Image
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  objectPosition: 'center',
                }}
                src={logoUrl}
                alt="Fifth Domain logo text with tagline 'securing cyber skill' below"
              />
            </View>
            <View style={styles.fdLogoBox}>
              <Image
                style={styles.poweredByLogo}
                src={LogoInlineOverlaySVG}
                alt="Fifth Domain logo text with tagline 'securing cyber skill' below"
              />
            </View>
          </View>
        ) : (
          <View style={styles.headerRow}>
            {LogoInlineColorSVG && (
              <Image
                style={styles.logo}
                src={LogoInlineColorSVG}
                alt="Fifth Domain logo text with tagline 'securing cyber skill' below"
              />
            )}
            <Text style={styles.bannerTitle}>fifthdomain.pro</Text>
          </View>
        )}
        <View style={styles.banner}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ width: '65%' }}>
              <View style={styles.bannerRow}>
                <Text style={styles.bannerHeading}>{userName}</Text>
              </View>
              <View style={{ ...styles.bannerRow, fontWeight: 800 }}>
                <Text style={styles.bannerSubHeading}>{assessmentName}</Text>
              </View>
              <View style={styles.bannerRow}>
                <Text
                  style={{
                    ...styles.bannerText,
                    color: 'lightgrey',
                    marginTop: 10,
                  }}
                >
                  {`Submitted: ${submittedDate}`}
                </Text>
              </View>
            </View>
            <View
              style={{
                ...styles.verticalLine,
                height: '45%',
                marginTop: 40,
              }}
            />
            <View style={{ width: '35%', alignSelf: 'flex-end' }}>
              <View style={styles.bannerRow}>
                <Text style={styles.banner2ndHeading}>Assessment Rank</Text>
                <Text style={{ ...styles.banner2ndHeading, marginLeft: 10 }}>
                  {`#${rank}`}
                </Text>
              </View>
              <View style={{ ...styles.bannerRow, marginTop: 5 }}>
                <Text style={styles.banner2ndHeading}>
                  {`Total number of ${teamOrParticipant}s`}
                </Text>
                <Text style={{ ...styles.banner2ndHeading, marginLeft: 10 }}>
                  {totalCandidates}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.pageContent}>
          <Text style={{ ...styles.heading, paddingBottom: 5 }}>
            Assessment Composition
          </Text>
          <View style={styles.horizontalLine} />
          <View>
            <Text style={{ ...styles.pageSubHeading, marginTop: 10 }}>
              Skills composition
            </Text>
            <Text style={{ ...styles.bodyText, marginTop: 5 }}>
              The heatmap illustrates skills on the x-axis and proficiency
              levels on the y-axis. Blue-grey boxes indicate assessed skills at
              specific proficiency levels.
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 10,
                marginBottom: 0,
              }}
            >
              <Text
                style={{
                  fontSize: 9,
                  marginRight: 10,
                  fontFamily: 'Montserrat',
                }}
              >
                Proficiency Levels
              </Text>
              {Object.keys(PROFICIENCY).map((proficiency, idx) => (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Text style={{ fontSize: 8, fontFamily: 'Montserrat' }}>
                    {`${idx + 1}: `}
                  </Text>
                  <View
                    style={{
                      backgroundColor: `${PROFICIENCY[proficiency].color}`,
                      borderRadius: 2,
                      marginRight: 5,
                      paddingRight: 5,
                      paddingLeft: 5,
                      paddingTop: 3,
                      paddingBottom: 3,
                    }}
                  >
                    <Text style={{ fontSize: 8, fontFamily: 'Montserrat' }}>
                      {proficiency}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            {heatmapChartImage && (
              <View
                style={{
                  marginTop: 10,
                  marginBottom: 1,
                  height: 120,
                }}
              >
                <Image src={heatmapChartImage} />
              </View>
            )}
          </View>
          <Text style={{ ...styles.pageCaption, marginBottom: 2 }}>
            Skills included in this assessment:
          </Text>
          <View style={styles.container}>
            {skills?.map((s) => (
              <View style={styles.item}>
                <Text style={{ fontSize: 9, color: 'rgb(33,33,33)' }}>
                  {`${s.skillName} (${s.skillAlias})`}
                </Text>
              </View>
            ))}
          </View>
          <View>
            <Text style={{ ...styles.pageSubHeading, marginTop: 10 }}>
              Techniques and Technologies composition
            </Text>
            <Text style={{ ...styles.bodyText, marginTop: 5 }}>
              {`The bar chart below shows all the techniques and technologies that are a part of this assessment. For each technique/technology, the number of segments on the bars represent the number of challenges that have that technique/technology included, while the length of the segment indicates its weightage . The green part represents the successful demonstration of that technique/technology by the ${teamOrParticipant}.`}
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 5,
              }}
            >
              {techniqueChartImage && (
                <Image
                  style={{
                    height: '125px',
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                  src={techniqueChartImage}
                />
              )}
              {technologyChartImage && (
                <Image
                  style={{
                    height: '125px',
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                  src={technologyChartImage}
                />
              )}
            </View>
          </View>
        </View>
        <Footer />
      </Page>
      <Page size="A4" style={styles.pageA4}>
        <View style={styles.pageContent}>
          <Text style={{ ...styles.heading, paddingBottom: 5 }}>
            Performance
          </Text>
          <View style={styles.horizontalLine} />
          <View
            style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}
          >
            <View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 5,
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    color: '#000',
                    fontFamily: 'Montserrat',
                  }}
                >
                  Assessment Rank:
                </Text>
                <View
                  style={{
                    borderRadius: '50%',
                    backgroundColor: '#001339',
                    color: '#fff',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 5,
                  }}
                >
                  <Text style={{ fontSize: 10, fontFamily: 'Montserrat' }}>
                    {`#${rank}`}
                  </Text>
                </View>
              </View>
              <View>
                <Text style={{ ...styles.bodyText }}>
                  {`The ${teamOrParticipant} was ranked ${getOrdinal(
                    rank,
                  )} in the assessment overall. The assessment rank is determined by the assessment score, which is the function of Success, Efficiency, and Speed, where Success is the biggest contributor to the score.Success is based on challenges solved successfully. Efficiency is the ability to solve challenged with least number of attempts, and speed is determined by the quickness in completing the assessment.`}
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  marginTop: 20,
                }}
              >
                <View style={{ width: '50%' }}>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={styles.flexDirectionColumn}>
                      <Text style={{ ...styles.pText, width: 130 }}>
                        {`Assessment Score: ${assessmentScore?.marker}`}
                      </Text>
                      <Text style={styles.bodyText}>
                        {`Cohort Average: ${assessmentScore?.avg}`}
                      </Text>
                    </View>
                    <ProgressIndicatorPdf
                      variant="grey"
                      range1={{
                        start: assessmentScore?.assessmentRange?.min,
                        end: assessmentScore?.assessmentRange?.max,
                      }}
                      range2={{
                        start: assessmentScore?.range?.min,
                        end: assessmentScore?.range?.max,
                      }}
                      marker={assessmentScore?.marker}
                    />
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View
                      style={{
                        width: 0.5,
                        height: '100%',
                        backgroundColor: 'grey',
                        marginRight: 10,
                      }}
                    />
                    <View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 10,
                        }}
                      >
                        <View style={styles.flexDirectionColumn}>
                          <Text style={{ ...styles.pText, width: 120 }}>
                            {`Success: ${success?.marker}`}
                          </Text>
                          <Text style={styles.bodyText}>
                            {`Cohort Average: ${success?.avg}`}
                          </Text>
                        </View>
                        <ProgressIndicatorPdf
                          variant="red"
                          range1={{
                            start: success?.assessmentRange?.min,
                            end: success?.assessmentRange?.max,
                          }}
                          range2={{
                            start: success?.range?.min,
                            end: success?.range?.max,
                          }}
                          marker={success?.marker}
                        />
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 10,
                        }}
                      >
                        <View style={styles.flexDirectionColumn}>
                          <Text style={{ ...styles.pText, width: 120 }}>
                            {`Efficiency: ${efficiency?.marker}`}
                          </Text>
                          <Text style={styles.bodyText}>
                            {`Cohort Average: ${efficiency?.avg}`}
                          </Text>
                        </View>
                        <ProgressIndicatorPdf
                          variant="orange"
                          range1={{
                            start: efficiency?.assessmentRange?.min,
                            end: efficiency?.assessmentRange?.max,
                          }}
                          range2={{
                            start: efficiency?.range?.min,
                            end: efficiency?.range?.max,
                          }}
                          marker={efficiency?.marker}
                        />
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 10,
                        }}
                      >
                        <View style={styles.flexDirectionColumn}>
                          <Text style={{ ...styles.pText, width: 120 }}>
                            {`Speed: ${speed?.marker}`}
                          </Text>
                          <Text style={styles.bodyText}>
                            {`Cohort Average: ${speed?.avg}`}
                          </Text>
                        </View>
                        <ProgressIndicatorPdf
                          variant="green"
                          range1={{
                            start: speed?.assessmentRange?.min,
                            end: speed?.assessmentRange?.max,
                          }}
                          range2={{
                            start: speed?.range?.min,
                            end: speed?.range?.max,
                          }}
                          marker={speed?.marker}
                        />
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ width: '50%' }}>
                  <Text style={{ ...styles.bodyText }}>
                    In the distribution bars, the light coloured part in the
                    base represents the range covering lowest and highest scores
                    of the cohort.
                  </Text>
                  <Text style={{ ...styles.bodyText }}>
                    The darker region represents one standard deviation above
                    and below the cohort average. Simply put, a wider shaded
                    region indicates that the scores of the cohort are more
                    spread out from the average.
                  </Text>
                  <Text style={{ ...styles.bodyText }}>
                    Conversely, a smaller shaded region suggests that the
                    majority of scores are clustered closely around the average.
                  </Text>
                  <Text style={{ ...styles.bodyText }}>
                    {`The dark line on the bar shows where the ${teamOrParticipant}'s score is for that measure.`}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <Text style={{ ...styles.pageSubHeading, marginTop: 10 }}>
            Performance Triangle
          </Text>
          <View style={{ width: '100%', marginTop: 5 }}>
            <Text style={styles.bodyText}>
              {`The performance triangle displays overall ${teamOrParticipant} assessment performance incorporating Success, Efficiency and Speed metrics. The dark grey line shows the cohort average, and the blue line shows the ${teamOrParticipant} score.`}
            </Text>
          </View>
          <View style={{ width: '100%', marginTop: 5 }}>
            {performanceTriangleChartImage && (
              <Image
                style={{ height: 190, width: '100%', objectFit: 'cover' }}
                src={performanceTriangleChartImage}
              />
            )}
          </View>
          <Text style={{ ...styles.pageSubHeading, marginTop: 30 }}>
            Performance in Skills
          </Text>
          <Text
            style={{
              ...styles.bodyText,
              marginTop: 5,
              fontFamily: 'Montserrat',
            }}
          >
            {`Below, you'll find the ${
              teamBased ? 'team' : 'participant'
            }'s performance in assessed skills. The performance score is a function of the success in that skill as well as the speed and efficiency.
            The blue line represents the ${teamOrParticipant}'s performance, while the
            dashed line shows the cohort's average.`}
          </Text>
          {performanceSkillsChartImage && (
            <Image
              style={{ height: 120, width: '100%' }}
              src={performanceSkillsChartImage}
            />
          )}
          <View
            style={{
              marginTop: 20,
              marginBottom: 5,
            }}
          >
            <PdfTable
              columns={skillTableColumns}
              rows={lineChartRows || []}
              showHeader
            />
          </View>
        </View>
        <View style={{ paddingHorizontal: 35 }}>
          <Text style={{ ...styles.heading, paddingBottom: 5 }}>Approach</Text>
          <View style={styles.horizontalLine} />
          <Text style={{ ...styles.bodyText, marginTop: 5 }}>
            {`This visualisation details the ${teamOrParticipant}'s journey by chronologically displaying when they viewed, started, attempted, and solved a challenge, revealing their time allocation and assessment approach.`}
          </Text>
          <ProficiencyApproachTablePdf
            taskAttempts={allTaskAttemptsUserWise}
            taskAttemptsDetail={taskAttemptsDetail}
            startDateTime={startDateTime}
            taskNotes={taskNotes}
            tasks={tasks}
            completedTasks={completedTasks}
            selectedUserIds={selectedUserIds}
            teamBased={teamBased}
            tasksOpened={tasksOpened}
          />
        </View>
        <Footer />
      </Page>
    </Document>
  );
};

InsightsPdfReport.propTypes = {
  userName: PropTypes.string.isRequired,
  assessmentName: PropTypes.string.isRequired,
  insightsData: PropTypes.shape({}).isRequired,
  techniqueChartImage: PropTypes.shape({}).isRequired,
  technologyChartImage: PropTypes.shape({}).isRequired,
  performanceSkillsChartImage: PropTypes.shape({}).isRequired,
  performanceTriangleChartImage: PropTypes.shape({}).isRequired,
  heatmapChartImage: PropTypes.shape({}).isRequired,
  logoUrl: PropTypes.string,
};

InsightsPdfReport.defaultProps = {
  logoUrl: '',
};

export default InsightsPdfReport;
