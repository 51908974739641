import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useQuery, gql } from '@apollo/client';
import {
  FdTable,
  FdTypography,
  FdLoadingSpinner,
} from '@fifthdomain/fe-shared';
import { listGroupMembersByGroupId } from '../../graphql/queries';

const TeamMembers = ({ assessmentData }) => {
  const { data: groupMembers, loading: groupMembersLoading } = useQuery(
    gql(listGroupMembersByGroupId),
    {
      variables: {
        groupId: assessmentData?.groupId,
      },
      skip: !assessmentData,
    },
  );

  if (groupMembersLoading) {
    return <FdLoadingSpinner />;
  }

  const userGroupMembers = groupMembers?.listGroupMembersByGroupId?.filter(
    (member) => !member.id.includes(assessmentData.userId),
  );

  const columns = [{ field: 'name', flex: 1, headerName: 'Participant Name' }];

  return (
    userGroupMembers?.length > 0 && (
      <Box height="415px" my={2} pb={4} data-cy="team-members">
        <Box my={2}>
          <FdTypography variant="subtitle1" data-cy="team-members-title">
            Team Members
          </FdTypography>
        </Box>
        <Box my={1}>
          <FdTypography variant="body2">{`Total Team Members: ${userGroupMembers?.length}`}</FdTypography>
        </Box>
        <FdTable
          id="manage-admins-table"
          toolbarSettings={{
            hide: true,
          }}
          selection={false}
          actions={[]}
          rows={userGroupMembers}
          columns={columns}
          gridId="assessor-participant-team-members"
        />
      </Box>
    )
  );
};

TeamMembers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  assessmentData: PropTypes.object.isRequired,
};

export default TeamMembers;
