import React, { useState } from 'react';
import PropTypes from 'prop-types';
import singleSpa from 'single-spa';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  FdProgress,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  ReactQueryClient,
} from '@fifthdomain/fe-shared';
import { listUserAssessmentsForRouter } from '../queries/customQueries';
import { createUserAssessment } from '../graphql/mutations';
import {
  listOnboardingsByUserId,
  listUserAssessments,
} from '../graphql/queries';

const UserAssessmentProvider = ({ assessmentId }) => {
  const [loading, setLoading] = useState(true);
  const globalSnap = useSnapshot(globalStore);
  const [createUserAssessmentMutation] = useMutation(gql(createUserAssessment));

  const { data: userAssessmentsData, loading: userAssessmentsLoading } =
    useQueryRecursive(gql(listUserAssessments), {
      variables: {
        filter: {
          userId: { eq: globalSnap.userId },
          userAssessmentAssessmentId: { eq: assessmentId },
        },
      },
    });

  const { data: onboardingData, loading: onboardingDataLoading } = useQuery(
    gql(listOnboardingsByUserId),
    {
      variables: {
        userId: globalSnap.userId,
      },
      skip: !globalSnap.userId,
    },
  );

  const getNavigateUrl = (_isOnboardingComplete, _userAssessmentId) =>
    _isOnboardingComplete
      ? `/assessor/assessment-start/${_userAssessmentId}`
      : `/assessor/survey/assess/${_userAssessmentId}`;

  useQueryRecursive(gql(listUserAssessmentsForRouter), {
    variables: {
      filter: {
        userId: { eq: globalSnap?.userId },
      },
    },
    onCompleted: (_data) => {
      const isOnboardingComplete =
        !!onboardingData?.listOnboardingsByUserId?.items.length;

      const userAssessmentId = _data?.listUserAssessments?.items.find(
        (us) => us.assessment?.id === assessmentId,
      )?.id;
      // user assessment exists, navigate directly
      if (userAssessmentId) {
        setLoading(false);
        const urlToNavigate = getNavigateUrl(
          isOnboardingComplete,
          userAssessmentId,
        );
        singleSpa.navigateToUrl(urlToNavigate);
        return;
      }

      if (userAssessmentsData?.listUserAssessments?.items?.length === 0) {
        // create a new user assessment and navigate with the new user assessmentId
        createUserAssessmentMutation({
          variables: {
            input: {
              userId: globalSnap?.userId,
              status: 'NOT_STARTED',
              userAssessmentAssessmentId: assessmentId,
            },
          },
          onCompleted: (_dataCreateUser) => {
            const newUserAssessmentId =
              _dataCreateUser?.createUserAssessment.id;
            // invalidate user assessments query
            ReactQueryClient.removeQueries(['ListUserAssessments']);
            setLoading(false);
            const navigateToUrl = getNavigateUrl(
              isOnboardingComplete,
              newUserAssessmentId,
            );
            window.location.replace(navigateToUrl);
          },
        });
      } else {
        singleSpa.navigateToUrl('/landing/landing-homepage');
      }
    },
  });

  if (loading || onboardingDataLoading || userAssessmentsLoading) {
    return <FdProgress />;
  }

  return <></>;
};

UserAssessmentProvider.propTypes = {
  assessmentId: PropTypes.string.isRequired,
};

export default UserAssessmentProvider;
