import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { FdProgress, useQueryRecursive } from '@fifthdomain/fe-shared';
import { getSystemTime } from '../graphql/queries';
import appStore from '../store';

const AppStoreInitializer = ({ children }) => {
  const { loading: getSystemTimeLoading } = useQueryRecursive(
    gql(getSystemTime),
    {
      onCompleted: (_data) => {
        const dateTime = Object.values(_data.getSystemTime).join(''); // to prep result returned by useQueryRecursive as a single string
        appStore.startSystemDateTimer(new Date(dateTime));
      },
      staleTime: { minutes: 0 },
    },
  );

  useEffect(() => {
    // stop sys-date timer on unmount
    return () => {
      appStore.stopSystemDateTimer();
    };
  }, []);

  if (getSystemTimeLoading) return <FdProgress />;

  return <>{children}</>;
};

AppStoreInitializer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppStoreInitializer;
