import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { FdCard, FdTextField } from '@fifthdomain/fe-shared';
import AssessmentType from './AssessmentType';

const Details = ({ heading, isDuplicateMode }) => {
  const { control } = useFormContext();
  return (
    <FdCard
      variant="outlined"
      heading={heading}
      subHeading={
        (!heading && 'Enter the assessment details.') ||
        (isDuplicateMode && 'Enter details for the duplicate assessment. ')
      }
      data-cy="details"
    >
      <Box display="flex" flexDirection="column">
        <AssessmentType />
        <Controller
          control={control}
          name="name"
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <Box mt={1} mb={2}>
              <FdTextField
                id="name"
                label="Name"
                required
                fullWidth
                error={error}
                helperText={
                  error
                    ? error.message
                    : 'Your assessment will appear under this name in your library. This will be displayed to participants.'
                }
                data-cy="name"
                {...rest}
                inputRef={ref}
              />
            </Box>
          )}
        />
        <Box mt={0} mb={0}>
          <Controller
            control={control}
            name="description"
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <Box mb={2}>
                <FdTextField
                  id="description"
                  label="Description"
                  fullWidth
                  multiline
                  rows={3}
                  error={error}
                  helperText={
                    error
                      ? error.message
                      : 'For internal use only. This will not be displayed to participants.'
                  }
                  data-cy="description"
                  {...rest}
                  inputRef={ref}
                />
              </Box>
            )}
          />
        </Box>
      </Box>
    </FdCard>
  );
};

Details.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  isDuplicateMode: PropTypes.bool.isRequired,
};

Details.defaultProps = {
  heading: 'Details',
};

export default Details;
