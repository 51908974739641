import _ from 'lodash';

// object: {}, value: any => returns any
export const getKeyByValue = (object, value) =>
  Object.keys(object).find((key) => object[key] === value);

// object: {}, values: [any] => returns [any]
export const getKeysByValues = (object, values) =>
  Object.keys(object).filter((key) => values.includes(object[key]));

// call it as [].sort(sortObjectArrayByField('abc','desc'))
export const sortObjectArrayByField = (field, order = 'asc') => {
  const sortOrder = order === 'desc' ? -1 : 1;

  return (a, b) => {
    const valA = _.get(a, field);
    const valB = _.get(b, field);

    if (valA < valB) {
      return -1 * sortOrder;
    }
    if (valA > valB) {
      return 1 * sortOrder;
    }
    return 0;
  };
};
