import * as Yup from 'yup';
import { isValid as isDateValid } from 'date-fns';

export const initialValues = {
  name: '',
  startDateTime: null,
  endDateTime: null,
};

Yup.addMethod(Yup.string, 'noWhitespace', function (message) {
  // eslint-disable-next-line react/no-this-in-sfc
  return this.test({
    name: 'noWhitespace',
    message,
    test: (value) => value && value.trim().length,
  });
});

export const validationSchema = Yup.object().shape(
  {
    name: Yup.string()
      .required('Please enter a name')
      .max(150, 'Name cannot be more than 150 characters long')
      .noWhitespace('Enter a valid assessment name'),
    startDateTime: Yup.date().when(['endDateTime'], {
      is: (endDateTime) => endDateTime && isDateValid(endDateTime),
      then: Yup.date()
        .min(new Date(), 'Start Date and Time cannot be in the past')
        .max(
          Yup.ref('endDateTime'),
          'Start Date and Time should be before End Date and Time',
        )
        .nullable(),
      otherwise: Yup.date()
        .min(new Date(), 'Start Date and Time cannot be in the past')
        .nullable(),
    }),
    endDateTime: Yup.date().when(['startDateTime'], {
      is: (startDateTime) => startDateTime && isDateValid(startDateTime),
      then: Yup.date()
        .required('Please enter an End Date and Time')
        .typeError('Please enter an End Date and Time')
        .min(
          Yup.ref('startDateTime'),
          'The End Date and Time must occur after the Start Date and Time',
        ),
      otherwise: Yup.date()
        .required('Please enter an End Date and Time')
        .min(new Date(), 'End Date and Time cannot be in the past')
        .typeError('Please enter an End Date and Time'),
    }),
  },
  [['startDateTime', 'endDateTime']],
);
