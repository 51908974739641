export const EDUCATION_TYPE = Object.freeze({
  YEAR12: 'Year 12 or equivalent',
  TRADE_TECHNICAL: 'Trade/technical/vocational training',
  CERTIFICATEIV: 'Certificate IV',
  DIPLOMA: 'Diploma',
  ADVANCED_DIPLOMA: 'Advanced Diploma/Associate Degree',
  BACHELOR_DEGREE: 'Bachelor Degree',
  MASTERS_DEGREE: 'Masters Degree',
  DOCTORATE_DEGREE: 'Doctorate Degree',
  OTHER_EDUCATION: 'Other',
});

export const FORMAL_TRAINING_TYPE = Object.freeze({
  SHORT_COURSES: 'Short courses',
  CERTIFICATEIV: 'Certificate IV',
  DIPLOMA: 'Diploma',
  ADVANCED_DIPLOMA: 'Advanced Diploma/Associate Degree',
  BACHELOR_DEGREE: 'Bachelor Degree',
  MASTERS_DEGREE: 'Masters Degree',
  DOCTORATE_DEGREE: 'Doctorate Degree',
  OTHER_FORMAL_TRAINING: 'Other',
});

export const CYBER_TRAINING_TYPE = Object.freeze({
  FORMAL_TRAINING: 'Formal Training',
  SELF_DIRECTED: 'Self-directed Learning',
  ON_JOB: 'On-job Training',
  NON_ACCREDITED: 'Non-accredited Training',
  NONE: 'I have not received any cyber training',
});

export const WORKING_INDUSTRY_TYPE = Object.freeze({
  FINANCE: 'Finance',
  DEFENCE: 'Defence',
  INTELLIGENCE: 'Intelligence',
  HEALTHCARE: 'Healthcare',
  EDUCATION: 'Education',
  GOVERNMENT: 'Government/Public Administration',
  INFORMATION_TECHNOLOGY: 'Information Technology',
  LAW_ENFORCEMENT: 'Law enforcement',
  MANUFACTURING: 'Manufacturing',
  RETAIL: 'Retail/E-commerce',
  OTHER_WORKING_INDUSTRY: 'Other',
});

export const ASSESSMENT_STATUS_SORT_ORDER = {
  STARTED: 1,
  NOT_STARTED: 2,
  NOT_COMPLETED: 3,
  FINISHED: 4,
};

export const SCOREBOARD_DURATION = Object.freeze({
  HOURS_24: 'Last 24 Hours',
  ALL_COMPETITION: 'All Competition',
});

export const ASSESSMENT_TYPES = {
  INDIVIDUAL_BASED: 'Individual-Based',
  TEAM_BASED: 'Team-Based',
};

export const TASK_ACTIVITY = Object.freeze([
  {
    value: 'NOT_WORKING',
    desc: "I'm not working on it",
  },
  { value: 'WORKING', desc: "I'm working on it" },
  { value: 'BLOCKED', desc: "I'm blocked" },
  { value: 'NEED_HELP', desc: 'I need help' },
]);

export const USER_PERMISSIONS = Object.freeze({
  MANAGE_GROUPS: 'manage-groups',
  MANAGE_ADMINS: 'manage-admins',
  MANAGE_TRAINING: 'manage-training',
  CREATE: 'create',
  VIEW_INSIGHTS: 'view-insights',
});

export const THEMES = Object.freeze([
  { value: 'LIGHT', label: 'Light Theme' },
  { value: 'RETRO', label: 'Retro Theme' },
]);

export const CONTENT_IMAGES = [
  'Analysis.jpg',
  'Intrusion Detection.jpg',
  'Incident Management.jpg',
  'Vulnerability.jpg',
  'Custom.jpg',
];

export const TAG_COLOUR = [
  {
    backgroundColor: '#FFE082',
    value: 'YELLOW',
  },
  {
    backgroundColor: '#FFAB91',
    value: 'ORANGE',
  },
  {
    backgroundColor: '#F48FB1',
    value: 'PINK',
  },
  {
    backgroundColor: '#CE93D8',
    value: 'PURPLE',
  },
  {
    backgroundColor: '#40C4FF',
    value: 'BLUE',
  },
  {
    backgroundColor: '#80CBC4',
    value: 'TEAL',
  },
];

export const SPECIALTY_COLOR = {
  Intelligence: 'rgba(163, 40, 237, 1)',
  Protection: 'rgba(78, 78, 255, 1)',
  Detection: 'rgba(200, 73, 164, 1)',
  Investigation: 'rgba(62, 179, 214, 1)',
  Penetration: 'rgba(151, 177, 255, 1)',
  Engineering: 'rgba(164, 184, 209, 1)',
};

export const PROFICIENCY = {
  Novice: { color: 'rgba(118, 209, 165, 1)', range: '1-2', level: 1 },
  'Advanced Beginner': {
    color: 'rgba(151, 180, 255, 1)',
    range: '3-4',
    level: 2,
  },
  Competent: { color: 'rgba(246, 185, 113, 1)', range: '5-6', level: 3 },
  Proficient: { color: 'rgba(227, 128, 187, 1)', range: '7-8', level: 4 },
  Expert: { color: 'rgba(213, 83, 83, 1)', range: '9-10', level: 5 },
};

export const proficiencyLevels = [
  'Novice',
  'Advanced Beginner',
  'Competent',
  'Proficient',
  'Expert',
];

export const lineChartColors = [
  '#757575',
  '#9C27B0',
  '#009688',
  '#1E88E5',
  '#D32F2F',
  '#9E9D24',
];

export const defaultLabDuration = 120;
