import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdTable, FdSkeleton } from '@fifthdomain/fe-shared';

const InsightsTable = ({
  tableWidth,
  title,
  rows,
  columns,
  loading,
  ...props
}) => {
  return (
    <FdSkeleton loading={loading} height="532px">
      <Box
        mt={2}
        mb={2}
        height="532px"
        width={tableWidth}
        data-cy={`${title}-table`}
        sx={{
          '& .MuiDataGrid-cell': {
            padding: '0 1px !important',
          },
        }}
      >
        <FdTable
          pinnedColumns={{ left: ['name'] }}
          defaultMuiToolbarSettings={{
            showMuiDefaultToolbar: true,
            columnsButton: true,
          }}
          actions={[]}
          rows={rows}
          columns={columns}
          pagination
          visibleSelection
          rowsPerPageOptions={[5, 10, 20]}
          tablePageSize={5}
          gridId="assessor-participant-insights"
          {...props}
        />
      </Box>
    </FdSkeleton>
  );
};

InsightsTable.propTypes = {
  tableWidth: PropTypes.string,
  title: PropTypes.node.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool,
};

InsightsTable.defaultProps = {
  tableWidth: '100%',
  loading: false,
};

export default InsightsTable;
