import { proficiencyLevels } from '../../constants';

export const getAssessmentChallengesTechniques = (tasks) => {
  const uniqueTechniques = tasks
    .flatMap(
      (task) =>
        task.skills?.items?.flatMap(
          (skill) =>
            skill.techniqueTags?.items?.map(
              (techniqueTag) => techniqueTag.techniqueTag?.name,
            ) || [],
        ) || [],
    )
    .reduce((acc, technique) => {
      if (technique && !acc.includes(technique)) acc.push(technique);
      return acc;
    }, []);

  return tasks.reduce(
    (acc, task) => {
      const taskName = task.name;
      const { recommendedPoints } = task;

      const taskTechniques =
        [
          ...new Set(
            task.skills?.items?.flatMap(
              (skill) =>
                skill.techniqueTags?.items?.map(
                  (techniqueTag) => techniqueTag.techniqueTag?.name,
                ) || [],
            ),
          ),
        ] || [];

      taskTechniques.forEach((technique) => {
        const techniquesLength = taskTechniques.length || 1;
        const value = Math.floor(recommendedPoints / techniquesLength);

        const techniqueData = acc.find((item) => item.technique === technique);
        if (techniqueData) {
          techniqueData[taskName] = value;
        } else {
          acc.push({ technique, [taskName]: value });
        }
      });

      return acc;
    },
    uniqueTechniques.map((technique) => ({ technique })),
  );
};

export const getAssessmentChallengesTechnologies = (tasks) => {
  const uniqueTechnologies = tasks
    .flatMap(
      (task) =>
        task.technologyTags?.items?.map(
          (technologyTag) => technologyTag.technologyTag?.name,
        ) || [],
    )
    .reduce((acc, technology) => {
      if (technology && !acc.includes(technology)) acc.push(technology);
      return acc;
    }, []);

  return uniqueTechnologies.map((technology) => {
    const challenges = tasks.reduce((acc, task) => {
      const taskName = task.name;
      const { recommendedPoints } = task;
      const technologies =
        [
          ...new Set(
            task.technologyTags?.items?.map(
              (technologyTag) => technologyTag.technologyTag?.name,
            ),
          ),
        ] || [];

      if (technologies.includes(technology)) {
        const value = Math.floor(recommendedPoints / technologies.length);
        acc[taskName] = value;
      }

      return acc;
    }, {});

    return { technology, ...challenges };
  });
};

export const getSkillsProficienciesMappedData = (
  tasksSkillsProficiencies,
  skillsData,
) => {
  const proficiencySkills =
    Object.keys(tasksSkillsProficiencies)?.map((p) => ({
      proficiency: p,
      skills: [
        ...new Set(tasksSkillsProficiencies[p].flatMap((item) => item.skills)),
      ],
    })) || [];

  const skillsProficienciesData = proficiencyLevels.reduce(
    (acc, _proficiency, index) => {
      const id = index + 1;

      const data = skillsData?.map((skill) => {
        const foundProficiencySkills = proficiencySkills?.some(
          (p) =>
            Number(p.proficiency) === id &&
            p.skills?.map((_p) => _p.alias).some((_s) => _s === skill.alias),
        );

        return {
          x: skill.alias,
          proficiency: proficiencyLevels[index],
          skill: skill.name,
          y: foundProficiencySkills ? id : undefined,
        };
      });

      acc.push({
        id,
        data,
      });

      return acc;
    },
    [],
  );

  return skillsProficienciesData;
};
