import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { styles } from './styles';

const PdfTableRows = ({ columns, rows }) => (
  <>
    {rows.map((row) => (
      <View style={styles.tableRow} key={row.id}>
        {columns.map((column) => (
          <Text
            style={{
              width: column.width,
              textAlign: column.textAlign,
              fontSize: 12,
            }}
            key={`${row.id}${column.name}`}
          >
            {row[column.name]}
          </Text>
        ))}
      </View>
    ))}
  </>
);

PdfTableRows.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rows: PropTypes.array.isRequired,
};

export default PdfTableRows;
