export const getTasksByAssessmentId = /* GraphQL */ `
  query GetTasksByAssessmentId($id: ID!) {
    getAssessment(id: $id) {
      id
      orgId
      name
      description
      preMessage
      postMessage
      startDateTime
      videoUrl
      endDateTime
      showPostAssessmentReflection
      hours
      minutes
      teamBased
      enableJumpbox
      enableVPN
      tasks {
        items {
          id
          taskId
          task {
            id
            name
            category
            labId
            lab {
              id
              name
              vms {
                items {
                  id
                }
              }
              createdAt
              updatedAt
            }
            specialtyId
            specialty {
              name
              skills {
                items {
                  id
                  name
                  alias
                }
              }
            }
            skills {
              items {
                skillId
                skill {
                  id
                  name
                  alias
                }
                techniqueTags {
                  items {
                    techniqueTag {
                      name
                    }
                  }
                }
              }
            }
            technologyTags {
              items {
                technologyTag {
                  name
                }
              }
            }
            type
            recommendedPoints
            description
            summary
            difficulty
            estimatedSolveTime
            org {
              id
              name
            }
            userId
            user {
              id
              name
              org {
                name
                id
              }
            }
            competencies {
              items {
                category {
                  categoryCode
                  categoryName
                }
                area {
                  areaCode
                  areaName
                }
                competency {
                  description
                  id
                }
              }
            }
            hints {
              items {
                id
                taskId
                text
              }
            }
            tags {
              items {
                Tag {
                  color
                  id
                  name
                  orgId
                }
                updatedAt
              }
            }
            files {
              items {
                id
                name
                url
                type
                size
              }
            }
          }
          modulePartId
          modulePart {
            name
            description
            courseModuleId
            type
            duration
            expiry
            inactivityExpiry
            initialLabCount
            minLabCount
            deletionMode
            labId
          }
          hintReveals {
            items {
              id
              hintId
              taskAssessmentId
            }
          }
        }
      }
    }
  }
`;

export const getAssessmentWithTasks = /* GraphQL */ `
  query ListUserAssessmentsWithTasks(
    $userId: ID!
    $nextToken: String
    $limit: Int
  ) {
    listUserAssessments(
      filter: { userId: { eq: $userId } }
      nextToken: $nextToken
      limit: $limit
    ) {
      items {
        userId
        user {
          id
          orgId
        }
        id
        status
        startedOn
        userAssessmentAssessmentId
        groupId
        status
        finishedOn
        createdAt
        updatedAt
        assessment {
          id
          name
          description
          teamBased
          minutes
          hours
          endDateTime
          startDateTime
          postMessage
          preMessage
          enableVPN
          videoUrl
          showPostAssessmentReflection
          tasks {
            items {
              id
              task {
                id
                name
                recommendedPoints
                estimatedSolveTime
                difficulty
                description
                category
                modulePartId
                modulePart {
                  name
                  description
                  type
                  duration
                  expiry
                  inactivityExpiry
                  initialLabCount
                  minLabCount
                  labId
                  status
                  id
                }
                hints {
                  items {
                    id
                    taskId
                    text
                  }
                }
                files {
                  items {
                    id
                    name
                    url
                    type
                  }
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getTasksAttempts = /* GraphQL */ `
  query ListTasksAttempts(
    $taskAttemptUserAssessmentId: ID!
    $nextToken: String
    $limit: Int
  ) {
    listTaskAttempts(
      filter: {
        taskAttemptUserAssessmentId: { eq: $taskAttemptUserAssessmentId }
      }
      nextToken: $nextToken
      limit: $limit
    ) {
      items {
        id
        userId
        taskAttemptTaskId
        success
        taskAttemptUserAssessmentId
        task {
          id
          name
          recommendedPoints
        }
      }
      nextToken
    }
  }
`;

export const getTasksAttemptsByAssessmentId = /* GraphQL */ `
  query ListTasksAttemptsByAssessmentId(
    $assessmentId: ID!
    $nextToken: String
    $limit: Int
  ) {
    listTaskAttempts(
      filter: { assessmentId: { eq: $assessmentId } }
      nextToken: $nextToken
      limit: $limit
    ) {
      items {
        id
        userId
        success
        taskAttemptUserAssessmentId
        task {
          id
          name
        }
        createdAt
      }
      nextToken
    }
  }
`;

export const getAllTasks = /* GraphQL */ `
  query listTasks($limit: Int) {
    listTasks(limit: $limit, limit: 100000, filter: { status: { ne: DRAFT } }) {
      items {
        difficulty
        estimatedSolveTime
        description
        hints {
          items {
            id
            taskId
            text
          }
        }
        tags {
          items {
            Tag {
              color
              id
              name
            }
            updatedAt
          }
        }
        competencies {
          items {
            area {
              areaName
            }
          }
        }
        recommendedPoints
        name
        id
        category
        files {
          items {
            name
            type
            url
            size
          }
        }
        modulePart {
          name
          labId
          expiry
          lab {
            name
            status
          }
        }
      }
      nextToken
    }
  }
`;

// providing assessment id, return taskID and ID from taskassessment table.
export const getTaskAssessmentIdByAssessment = /* GraphQL */ `
  query getTaskAssessmentIdByAssessment($id: ID!) {
    getAssessment(id: $id) {
      tasks {
        items {
          id
          taskId
        }
      }
    }
  }
`;

export const getTaskAttemptsAssessment = /* GraphQL */ `
  query ListTaskAttemptsAssessment($assessmentId: ID!, $limit: Int) {
    listTaskAttempts(
      filter: { assessmentId: { eq: $assessmentId } }
      limit: $limit
    ) {
      items {
        success
        createdAt
        userId
        task {
          id
          name
          difficulty
          category
          recommendedPoints
          estimatedSolveTime
        }
      }
      nextToken
    }
  }
`;

export const listTaskAttemptsByAssessmentId = /* GraphQL */ `
  query ListTaskAttemptsByAssessmentId(
    $assessmentId: ID!
    $taskAttemptTaskId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptsByAssessmentId(
      assessmentId: $assessmentId
      taskAttemptTaskId: $taskAttemptTaskId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        task {
          id
          name
          description
          externalId
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          userId
          status
          createdAt
          updatedAt
          competencies {
            items {
              id
              category {
                categoryCode
                categoryName
              }
              area {
                areaCode
                areaName
              }
              competency {
                description
                id
              }
            }
          }
          specialty {
            name
          }
          skills {
            items {
              skill {
                id
                name
              }
            }
          }
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          groupId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
          user {
            alias
            id
            name
          }
        }
        taskAttemptUserAssessmentId
        assessmentId
        success
        firstBlood
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTaskAttempts = /* GraphQL */ `
  query ListTaskAttempts(
    $filter: ModelTaskAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttempts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        task {
          id
          name
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          createdAt
          updatedAt
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
          user {
            name
            id
          }
          assessment {
            name
            hours
            minutes
            tasks {
              items {
                taskId
              }
            }
          }
        }
        taskAttemptUserAssessmentId
        assessmentId
        success
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        users {
          items {
            user {
              name
              id
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listGroupsUsersByAssessmenId = /* GraphQL */ `
  query ListGroupsUsersByAssessmenId(
    $assessmentId: ID!
    $groupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByAssessmenId(
      assessmentId: $assessmentId
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        groupId
        group {
          id
          name
          description
          createdAt
          updatedAt
          users {
            items {
              user {
                id
                name
                email
              }
            }
          }
        }
        assessmentId
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          createdAt
          updatedAt
        }
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listAssessmentsAndTasks = /* GraphQL */ `
  query ListAssessments(
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        orgId
        name
        tasks {
          items {
            id
            taskId
            task {
              category
              difficulty
              estimatedSolveTime
              id
              name
              recommendedPoints
              competencies {
                items {
                  id
                  taskId
                  area {
                    areaName
                  }
                  competency {
                    description
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const listGroupsByAssessmentId = /* GraphQL */ `
  query ListGroupsByAssessmentId($assessmentId: ID!) {
    listGroupsByAssessmenId(assessmentId: $assessmentId) {
      items {
        group {
          name
          id
          users {
            items {
              user {
                alias
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const listTaskActivities = /* GraphQL */ `
  query ListTaskActivities(
    $filter: ModelTaskActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        activity
        userId
        assessmentId
        taskId
      }
      nextToken
    }
  }
`;

export const listTaskActivitiesByAssessmentId = /* GraphQL */ `
  query ListTaskActivitiesByAssessmentId(
    $assessmentId: ID!
    $groupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskActivitiesByAssessmentId(
      assessmentId: $assessmentId
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activity
        userId
        assessmentId
        taskId
      }
      nextToken
    }
  }
`;

export const listTaskOpeneds = /* GraphQL */ `
  query ListTaskOpeneds(
    $filter: ModelTaskOpenedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskOpeneds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        startedSolving
        startedSolvingAt
        taskOpenedTaskId
        createdAt
        userId
        task {
          id
          name
          description
          summary
          difficulty
          recommendedPoints
          specialty {
            name
            skills {
              items {
                id
                name
                alias
              }
            }
          }
          skills {
            items {
              skillId
              skill {
                id
                name
                alias
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getUserAssessment = /* GraphQL */ `
  query GetUserAssessment($id: ID!) {
    getUserAssessment(id: $id) {
      id
      userId
      user {
        id
        orgId
      }
      userAssessmentAssessmentId
      groupId
      status
      assessment {
        id
        showPostAssessmentReflection
        name
        startDateTime
        endDateTime
        hours
        minutes
        preMessage
        teamBased
        enableJumpbox
        enableVPN
        videoUrl
        modulePartId
        modulePart {
          labId
        }
        tasks {
          items {
            task {
              id
              name
              recommendedPoints
              estimatedSolveTime
              difficulty
              description
              type
              category
              specialty {
                name
              }
              org {
                id
                name
                createdAt
                updatedAt
              }
              labId
              modulePartId
              modulePart {
                name
                description
                type
                duration
                expiry
                inactivityExpiry
                initialLabCount
                minLabCount
                labId
                status
                id
              }
              hints {
                items {
                  id
                  taskId
                  text
                }
              }
              files {
                items {
                  id
                  name
                  url
                  type
                }
              }
              skills {
                items {
                  skillId
                  skill {
                    name
                  }
                  techniqueTags {
                    items {
                      techniqueTag {
                        name
                      }
                    }
                  }
                }
              }
            }
            id
            modulePartId
            modulePart {
              name
              description
              type
              duration
              expiry
              inactivityExpiry
              initialLabCount
              minLabCount
              labId
              status
              id
            }
          }
        }
      }
      startedOn
      finishedOn
      createdAt
      updatedAt
    }
  }
`;

export const listHintReveals = /* GraphQL */ `
  query ListHintReveals(
    $filter: ModelHintRevealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHintReveals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hintId
        taskAssessmentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listUrls = /* GraphQL */ `
  query ListUrls(
    $filter: ModelUrlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUrls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        theme
        url
        key
        orgId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listAllFinishedAssessments = /* GraphQL */ `
  query ListAllFinishedAssessments(
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        participantEventType
        users {
          items {
            id
            userId
            userAssessmentAssessmentId
            groupId
            status
            startedOn
            finishedOn
            createdAt
            updatedAt
            user {
              id
              email
              type
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listCourseUsersByOrgId = /* GraphQL */ `
  query listCourseUsersByOrgId($orgId: ID!) {
    listCourseUsersByOrgId(orgId: $orgId) {
      items {
        user {
          id
          email
          name
          type
        }
        courseId
        course {
          name
        }
      }
    }
  }
`;
export const listUserAssessmentsForRouter = /* GraphQL */ `
  query ListUserAssessments(
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          type
          name
        }
        assessment {
          id
          orgId
          creator {
            org {
              name
            }
          }
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          videoUrl
          createdBy
          participantEventType
          status
          tasks {
            items {
              id
              task {
                id
                recommendedPoints
                attempts(filter: { success: { eq: true } }) {
                  items {
                    id
                    success
                    assessmentId
                    taskAttemptUserAssessmentId
                    taskAttemptTaskId
                    task {
                      recommendedPoints
                    }
                  }
                }
              }
            }
          }
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSkills = /* GraphQL */ `
  query ListSkills(
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        alias
        specialty {
          name
          id
        }
      }
      nextToken
    }
  }
`;
export const getAssessment = /* GraphQL */ `
  query GetAssessment($id: ID!) {
    getAssessment(id: $id) {
      id
      orgId
      name
      description
      teamBased
      startDateTime
      endDateTime
      hours
      minutes
      preMessage
      postMessage
      createdBy
      participantEventType
      createdAt
      updatedAt
    }
  }
`;

export const listAffliationsByUserId = /* GraphQL */ `
  query ListAffliationsByUserId(
    $userId: ID!
    $orgId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserOrgAffliationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAffliationsByUserId(
      userId: $userId
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        status
      }
      nextToken
    }
  }
`;
