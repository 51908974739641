import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';
import { FdExternalLink } from '@fifthdomain/fe-shared';
import { listAffliationsByUserId } from '../../../queries/customQueries';

const ProfileHyperlink = ({ userId, children }) => {
  const [profileLink, setProfileLink] = useState('');
  useQuery(gql(listAffliationsByUserId), {
    fetchPolicy: 'cache-first',
    skip: !userId,
    variables: {
      userId,
    },
    onCompleted: (_data) => {
      const affiliationDetails =
        _data?.listAffliationsByUserId?.items?.filter(
          (a) => a?.status === 'ACTIVE',
        )[0] || {};
      setProfileLink(
        affiliationDetails?.status === 'ACTIVE'
          ? `/competitions/affiliated-view-user/${userId}`
          : `/competitions/non-affiliated-view-user/${userId}`,
      );
    },
  });

  return (
    <Box display="flex" mr={2}>
      {profileLink ? (
        <FdExternalLink href={profileLink}>{children}</FdExternalLink>
      ) : (
        children
      )}
    </Box>
  );
};

ProfileHyperlink.propTypes = {
  userId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ProfileHyperlink;
