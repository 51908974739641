import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@material-ui/core';
import { View } from '@react-pdf/renderer';

const ProgressIndicatorPdf = ({ variant, range1, range2, marker }) => {
  const { grey } = colors;
  const baseColor = colors[variant];
  const rangeColor1 = variant === 'grey' ? baseColor[400] : baseColor[100];
  const rangeColor2 = variant === 'grey' ? baseColor[500] : baseColor[200];

  if (!range1 || !range2) {
    return null;
  }

  return (
    <View
      style={{
        width: 100,
        height: 12,
        position: 'relative',
        marginTop: 10,
        marginBottom: 2,
        marginLeft: 10,
        justifyContent: 'center',
      }}
    >
      <View
        style={{
          backgroundColor: grey[300],
          borderRadius: '4px',
          width: '100%',
          height: '100%',
        }}
      />
      <View
        style={{
          backgroundColor: rangeColor1,
          borderRadius: '4px',
          position: 'absolute',
          top: 0,
          left: `${range1?.start}%`,
          width: `${range1?.end - range1?.start}%`,
          height: '100%',
          borderLeft: '1px solid rgba(255, 255, 255, 1)',
          borderRight: '1px solid rgba(255, 255, 255, 1)',
        }}
      />
      <View
        style={{
          backgroundColor: rangeColor2,
          borderRadius: '4px',
          position: 'absolute',
          top: 0,
          left: `${range2?.start}%`,
          width: `${range2?.end - range2?.start}%`,
          height: '100%',
        }}
      />
      <View
        style={{
          backgroundColor: '#4D4B4B',
          borderRadius: '2px',
          position: 'absolute',
          top: 0,
          left: `${marker}%`,
          width: '5px',
          height: '100%',
        }}
      />
    </View>
  );
};
ProgressIndicatorPdf.propTypes = {
  variant: PropTypes.oneOf(['red', 'orange', 'green', 'grey']).isRequired,
  range1: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }).isRequired,
  range2: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }).isRequired,
  marker: PropTypes.number.isRequired,
};

export default ProgressIndicatorPdf;
