import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery, gql } from '@apollo/client';
import { Box } from '@material-ui/core';
import {
  FdTypography,
  FdProgress,
  setShowNav,
  FdButton,
  VDIConsole,
} from '@fifthdomain/fe-shared';
import { getVmConsole, getLabInstance } from '../graphql/queries';

const LabVDI = () => {
  const { labId, vmId } = useParams();

  useEffect(() => {
    setShowNav(false);
  }, []);

  const [fetchConsole, { loading: consoleLoading, data: consoleData }] =
    useLazyQuery(gql(getVmConsole), {
      getVmConsole: 'network-only',
    });

  const { loading: getLabInstanceLoading } = useQuery(gql(getLabInstance), {
    variables: {
      id: labId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      // Check if VM is in lab instance
      const vmList = data?.getLabInstance?.vms?.items?.filter(
        (vm) => vm.id === vmId,
      );

      // Check that the VM is active and has VDI access
      const status = data?.getLabInstance?.status;
      if (vmList?.length > 0 && vmList[0]?.hasVdi && status === 'READY') {
        document.title = `${vmList[0].name}`;
        fetchConsole({
          variables: {
            labInstanceVmId: vmId,
          },
        });
      }
    },
  });

  if (getLabInstanceLoading || consoleLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        height="100%"
      >
        <FdTypography variant="h4">Fetching MKS Ticket...</FdTypography>
        <Box m={1} />
        <FdProgress />
      </Box>
    );
  }
  return (
    <Box>
      {consoleData ? (
        <VDIConsole
          MKSTicket={consoleData?.getVmConsole?.location}
          keepAwakeUrl={consoleData?.getVmConsole?.keepAwakeUrl}
        />
      ) : (
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          height="100%"
        >
          <FdTypography variant="h4">Unable to acquire MKS ticket</FdTypography>
          <FdTypography variant="h4">
            Please verify that lab is powered on
          </FdTypography>
          <Box m={4} />
          <FdButton size="large" onClick={() => window.location.reload()}>
            Retry
          </FdButton>
        </Box>
      )}
    </Box>
  );
};
export default LabVDI;
