import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdTypography, FdSkeleton } from '@fifthdomain/fe-shared';
import { BarNivo } from '../../Charts';

const legends = [
  {
    color: '#35C3A1',
    text: 'Technique/technology achieved in this challenge',
  },
  {
    color: '#616161',
    text: 'Technique/technology not achieved in this challenge.',
  },
  {
    color: '#BDBDBD',
    text: 'Technique/technology offered in the entire assessment',
  },
];

const Legend = () => (
  <Box className="flex flex-col w-2/6 mt-10 ml-5">
    {legends.map((t) => (
      <Box className="flex mt-1">
        <Box className="h-2 w-3 mr-1" style={{ backgroundColor: t.color }} />
        <FdTypography variant="captiontext2">{t.text}</FdTypography>
      </Box>
    ))}
  </Box>
);

const TechGraphs = ({
  loading,
  tasks,
  completedTasks,
  selectedUserIds,
  containerHeight,
  graphHeight,
  tasksInAssessment,
  forPdfDownload,
}) => {
  const getTechniqueTags = (_tasks, _tag) =>
    _tasks?.items.filter((item) =>
      item.task.skills.items.some((skillItem) =>
        skillItem.techniqueTags.items.some(
          (tagItem) => tagItem.techniqueTag.name === _tag,
        ),
      ),
    );
  const getTechnologyTags = (_tasks, _tag) =>
    _tasks?.items.filter((item) =>
      item.task.technologyTags.items.some(
        (techItem) => techItem.technologyTag.name === _tag,
      ),
    );
  const completedTasksBySelectedUserIds = completedTasks?.filter((ct) =>
    selectedUserIds.includes(ct.userId),
  );
  const getTechPointsInAssessment = (_tasks, _tag, _type) => {
    const tagItemsFound =
      _type === 'technique'
        ? getTechniqueTags(_tasks, _tag)
        : getTechnologyTags(_tasks, _tag);
    const completedTaskIds = completedTasksBySelectedUserIds?.map(
      (ct) => ct.taskId,
    );
    const taskNotSolvedPoints = tagItemsFound
      .filter((ct) => !completedTaskIds.includes(ct.taskId))
      .map((tp) => ({
        [`~pointsAvailableInAssessment${tp?.task?.name}`]:
          tp?.task?.recommendedPoints,
      }));
    return taskNotSolvedPoints;
  };

  const techniquesInAssessment = [
    ...new Set(
      tasks?.items
        ?.map((item) => item.task.skills.items)
        .flat()
        .map((skillItem) => skillItem.techniqueTags.items)
        .flat()
        .map((tagItem) => tagItem.techniqueTag.name),
    ),
  ].sort();

  const technologiesInAssessment = [
    ...new Set(
      tasks?.items
        ?.map((item) => item.task.technologyTags.items)
        .flat()
        .map((tagItem) => tagItem.technologyTag.name),
    ),
  ].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  const techniquesBarChartData = techniquesInAssessment?.map((tag) => {
    const tagItemsFound = getTechniqueTags(tasks, tag);
    const taskIds = tagItemsFound?.map((f) => f.task?.id);
    const taskPoints = completedTasksBySelectedUserIds
      .filter((ct) => taskIds.includes(ct.taskId))
      .map((tp) => ({
        [tp?.task?.name]: tp?.task?.recommendedPoints,
      }));
    const completedTaskIds = completedTasksBySelectedUserIds?.map(
      (ct) => ct.taskId,
    );
    const taskNotSolvedPoints = tagItemsFound
      .filter((ct) => !completedTaskIds.includes(ct.taskId))
      .map((tp) => ({
        [`~pointsAvailable${tp?.task?.name}`]: tp?.task?.recommendedPoints,
      }));
    const taskNotSolvedAssessment = tasksInAssessment
      ? getTechPointsInAssessment(tasksInAssessment, tag, 'technique')
      : [];
    return {
      tag,
      ...taskPoints.reduce((result, item) => {
        const [key, value] = Object.entries(item)[0];
        result[key] = value;
        return result;
      }, {}),
      ...taskNotSolvedPoints.reduce((result, item) => {
        const [key, value] = Object.entries(item)[0];
        result[key] = value;
        return result;
      }, {}),
      ...taskNotSolvedAssessment.reduce((result, item) => {
        const [key, value] = Object.entries(item)[0];
        result[key] = value;
        return result;
      }, {}),
      taskPoints,
      taskNotSolvedPoints,
      taskNotSolvedAssessment,
    };
  });
  const sortByPoints = (a, b) => b.taskPoints?.length - a.taskPoints?.length;

  const techniquesBarChartDataFinal = forPdfDownload
    ? techniquesBarChartData.sort(sortByPoints).slice(0, 10)
    : techniquesBarChartData;

  const techniqueKeys = [
    ...new Set(
      techniquesBarChartDataFinal.flatMap((item) => [
        ...item.taskPoints?.flatMap(Object.keys),
        ...item.taskNotSolvedPoints?.flatMap(Object.keys),
        ...item.taskNotSolvedAssessment?.flatMap(Object.keys),
      ]),
    ),
  ].sort();

  const technologiesBarChartData = technologiesInAssessment?.map((tag) => {
    const tagItemsFound = getTechnologyTags(tasks, tag);
    const taskIds = tagItemsFound?.map((f) => f.task?.id);
    const taskPoints = completedTasksBySelectedUserIds
      .filter((ct) => taskIds.includes(ct.taskId))
      .map((tp) => ({
        [tp?.task?.name]: tp?.task?.recommendedPoints,
      }));
    const completedTaskIds = completedTasksBySelectedUserIds?.map(
      (ct) => ct.taskId,
    );
    const taskNotSolvedPoints = tagItemsFound
      .filter((ct) => !completedTaskIds.includes(ct.taskId))
      .map((tp) => ({
        [`~pointsAvailable${tp?.task?.name}`]: tp?.task?.recommendedPoints,
      }));
    const taskNotSolvedAssessment = tasksInAssessment
      ? getTechPointsInAssessment(tasksInAssessment, tag, 'technology')
      : [];

    return {
      tag,
      ...taskPoints.reduce((result, item) => {
        const [key, value] = Object.entries(item)[0];
        result[key] = value;
        return result;
      }, {}),
      ...taskNotSolvedPoints.reduce((result, item) => {
        const [key, value] = Object.entries(item)[0];
        result[key] = value;
        return result;
      }, {}),
      ...taskNotSolvedAssessment.reduce((result, item) => {
        const [key, value] = Object.entries(item)[0];
        result[key] = value;
        return result;
      }, {}),
      taskPoints,
      taskNotSolvedPoints,
      taskNotSolvedAssessment,
    };
  });

  const technologiesBarChartDataFinal = forPdfDownload
    ? technologiesBarChartData.slice(0, 10)
    : technologiesBarChartData;

  const technologyKeys = [
    ...new Set(
      technologiesBarChartDataFinal.flatMap((item) => [
        ...item.taskPoints?.flatMap(Object.keys),
        ...item.taskNotSolvedPoints?.flatMap(Object.keys),
        ...item.taskNotSolvedAssessment?.flatMap(Object.keys),
      ]),
    ),
  ].sort();

  const getColor = (_data) => {
    if (tasksInAssessment) {
      if (_data.id.includes('~pointsAvailable')) return '#9E9E9E';
      if (_data.id.includes('~pointsAvailableInAssessment')) return '#BDBDBD';
    } else if (_data.id.includes('~pointsAvailable')) {
      return '#BDBDBD';
    }
    return '#35C3A1';
  };

  const showTooltip = (d) => (
    <Box
      style={{
        backgroundColor: '#777',
        color: '#fff',
        padding: '4px 8px',
        fontSize: '12px',
      }}
    >
      {d.id
        .replace('~pointsAvailableInAssessment', '')
        .replace('~pointsAvailable', '')}
    </Box>
  );

  return (
    <FdSkeleton loading={loading} height="526px">
      <Box className="flex w-full">
        <Box
          height={containerHeight}
          mt={2}
          className="w-full overflow-y-auto"
          width="645px"
        >
          <FdTypography variant="subtitle2">
            Techniques in this assessment
          </FdTypography>
          <Box height={graphHeight} width={forPdfDownload ? '750px' : '100%'}>
            <BarNivo
              id={
                forPdfDownload ? 'technique-chart' : 'technique-chart-non-pdf'
              }
              data={techniquesBarChartDataFinal}
              keys={techniqueKeys}
              indexBy="tag"
              layout="horizontal"
              colors={getColor}
              margin={{
                top: 10,
                right: 50,
                bottom: 50,
                left: forPdfDownload ? 420 : 250,
              }}
              enableGridY={false}
              enableGridX={false}
              gridXValues={5}
              groupMode="stacked"
              enableLabel={false}
              animate={false}
              tooltip={showTooltip}
              innerPadding={1}
              theme={{
                ...(forPdfDownload ? { fontSize: 16 } : {}),
              }}
            />
          </Box>
          <FdTypography variant="subtitle2">
            Technologies in this assessment
          </FdTypography>
          <Box height={graphHeight} width={forPdfDownload ? '750px' : '100%'}>
            <BarNivo
              id={
                forPdfDownload ? 'technology-chart' : 'technology-chart-non-pdf'
              }
              data={technologiesBarChartDataFinal}
              keys={technologyKeys}
              indexBy="tag"
              layout="horizontal"
              colors={getColor}
              margin={{
                top: 10,
                right: 50,
                bottom: 50,
                left: forPdfDownload ? 420 : 250,
              }}
              enableGridY={false}
              enableGridX={false}
              gridXValues={5}
              groupMode="stacked"
              enableLabel={false}
              animate={false}
              tooltip={showTooltip}
              innerPadding={1}
              theme={{
                ...(forPdfDownload ? { fontSize: 16 } : {}),
              }}
            />
          </Box>
        </Box>
        {tasksInAssessment && <Legend />}
      </Box>
    </FdSkeleton>
  );
};

TechGraphs.propTypes = {
  loading: PropTypes.bool.isRequired,
  tasks: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.shape({})) })
    .isRequired,
  completedTasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedUserIds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  containerHeight: PropTypes.string.isRequired,
  graphHeight: PropTypes.string.isRequired,
  tasksInAssessment: PropTypes.arrayOf(PropTypes.shape({})),
  forPdfDownload: PropTypes.bool,
};

TechGraphs.defaultProps = {
  tasksInAssessment: undefined,
  forPdfDownload: false,
};

export default TechGraphs;
