import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FdButton, FdTextField } from '@fifthdomain/fe-shared';
import FdTextView from '../FdTextView';
import { warningToastMessage } from '../../shared/utils/toast';

const NotesSubmission = ({
  onClickSubmit,
  control,
  Controller,
  reset,
  getValues,
  trigger,
}) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <form>
      <Box mt={1}>
        <Controller
          control={control}
          name="notes"
          render={({ field, fieldState: { error } }) => (
            <Box mt={1} mb={2}>
              {editMode ? (
                <FdTextField
                  id="notes"
                  label="Add Notes for Assessor"
                  required
                  fullWidth
                  multiline
                  rows={5}
                  placeholder="Add notes to explain your approach to your assessment organiser (max 1,000 characters).
                This could include describing what steps you took, why you took those steps, or what you might have tried if you had more time."
                  error={error}
                  helperText={error && error.message}
                  data-cy="flag"
                  {...field}
                />
              ) : (
                <FdTextView
                  label="Add Notes for Assessor"
                  value={field.value}
                />
              )}
            </Box>
          )}
        />
        <Box mt={1}>
          {editMode ? (
            <Box display="flex">
              <FdButton
                size="large"
                onClick={async () => {
                  if (await trigger('notes')) {
                    setEditMode(false);
                    onClickSubmit();
                  }
                }}
              >
                Save
              </FdButton>
              <Box ml={1}>
                <FdButton
                  size="large"
                  variant="tertiary"
                  onClick={() => {
                    setEditMode(false);
                    reset();
                    warningToastMessage('Notes not saved');
                  }}
                >
                  Cancel
                </FdButton>
              </Box>
            </Box>
          ) : (
            <FdButton size="large" onClick={() => setEditMode(true)}>
              {getValues('notes') ? 'Edit Notes' : 'Add Notes'}
            </FdButton>
          )}
        </Box>
      </Box>
    </form>
  );
};

NotesSubmission.propTypes = {
  onClickSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  Controller: PropTypes.elementType.isRequired,
  reset: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
};

export default NotesSubmission;
