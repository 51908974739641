import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';
import Cohort from '../../shared/images/cohort.svg';
import { lineChartColors } from '../../constants';

const ComparisonChartLegend = ({
  chartData,
  teamBased,
  selectedCandidates,
}) => (
  <Box display="flex" width="15%" alignItems="flex-start" height="100%">
    <Box>
      <FdTypography variant="captiontext1">
        {`${teamBased ? 'Teams' : 'Participants'} selected to be compared`}
      </FdTypography>
      <Box mt={1}>
        <Box display="flex">
          <img src={Cohort} alt="cohort" style={{ marginRight: '1rem' }} />
          <FdTypography variant="body1">Cohort Average</FdTypography>
        </Box>
        <Box>
          {chartData?.map((cd) => (
            <Box display="flex" alignItems="center" mt={0.8}>
              <Box className="flex w-5 h-5 mr-1 items-center">
                <Box
                  className="rounded-full w-4 h-4"
                  style={{
                    backgroundColor:
                      lineChartColors[
                        selectedCandidates.indexOf(cd.userId) + 1
                      ],
                  }}
                />
              </Box>
              <FdTypography variant="body1">{cd?.id}</FdTypography>
              <Box ml={0.5}>
                <FdTypography variant="captiontext1" color="secondary">
                  {`Rank #${cd?.rank}`}
                </FdTypography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  </Box>
);

ComparisonChartLegend.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  teamBased: PropTypes.bool.isRequired,
  selectedCandidates: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ComparisonChartLegend;
