import { StyleSheet, Font } from '@react-pdf/renderer';
import MontserratRegular from '../../../resources/fonts/Montserrat-Regular.ttf';
import MontserratSemiBold from '../../../resources/fonts/Montserrat-SemiBold.ttf';

Font.register({
  family: 'Montserrat',
  fonts: [
    {
      src: MontserratRegular,
      fontWeight: 400,
    },
    {
      src: MontserratSemiBold,
      fontWeight: 600,
    },
  ],
});

export const styles = StyleSheet.create({
  pageA4: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  pageContent: {
    paddingTop: 10,
    paddingBottom: 30,
    paddingHorizontal: 35,
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'Montserrat',
  },
  headingNotFirstPage: {
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 16,
    fontFamily: 'Montserrat',
  },
  teamBasedFirstHeading: {
    paddingTop: 20,
    paddingBottom: 30,
    fontSize: 14,
    color: '#4C5AFF',
    fontFamily: 'Montserrat',
  },
  subHeading: {
    color: '#4C5AFF',
    paddingBottom: 5,
    fontSize: 14,
  },
  subHeadingNewPage: {
    color: '#4C5AFF',
    paddingBottom: 5,
    fontSize: 14,
    paddingTop: 25,
  },
  row: {
    flexDirection: 'row',
    paddingBottom: 20,
  },
  graph: {
    paddingTop: 0,
    paddingBottom: 0,
    objectFit: 'cover',
  },
  radarGraph: {
    padding: 0,
    margin: 0,
    height: 250,
    width: 525,
    objectFit: 'cover',
    top: -15,
  },
  gaugeGraph: {
    padding: 0,
    margin: 0,
    height: 200,
    width: 300,
    objectFit: 'cover',
  },
  text: {
    fontSize: 11,
    color: 'rgba(0, 0, 0, 0.54)',
    textAlign: 'justify',
    whiteSpace: 'nowrap',
    fontFamily: 'Montserrat',
  },
  percentageText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#4C5AFF',
  },
  topperName: {
    fontSize: 14,
    fontFamily: 'Montserrat',
  },
  topperText: {
    paddingTop: 5,
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: 'Montserrat',
  },
  teamHeading: {
    fontSize: 12,
    fontFamily: 'Montserrat',
  },
  gaugeValue: {
    fontSize: 20,
    color: '#4C5AFF',
    fontFamily: 'Montserrat',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
    borderBottom: '1px solid #4C5AFF',
  },
  logo: {
    width: 225,
    height: 'auto',
    paddingVertical: 20,
    paddingHorizontal: 20,
  },
  poweredByLogo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  customLogoContainer: {
    height: 130,
    width: 130,
    paddingVertical: 0,
    marginLeft: 20,
  },
  fdLogoBox: {
    height: 130,
    width: 130,
    paddingVertical: 0,
    marginRight: 20,
  },
  verticalLine: {
    width: 0.5,
    height: '100%',
    backgroundColor: '#fff',
  },
  horizontalLine: {
    height: 0.5,
    width: '100%',
    backgroundColor: '#000',
  },
  bodyText: {
    fontSize: 10,
    color: 'rgba(0, 0, 0, 0.54)',
    lineHeight: 1.5,
    fontFamily: 'Montserrat',
  },
  flexDirectionColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  blockHeading: {
    fontSize: 11,
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: 5,
    marginTop: 10,
    fontFamily: 'Montserrat',
  },
  blockSubHeading: {
    fontSize: 10,
    color: 'rgba(0, 0, 0, 0.54)',
    lineHeight: 1.5,
    fontFamily: 'Montserrat',
  },
  banner: {
    backgroundColor: '#131353',
    color: '#fff',
    paddingHorizontal: 40,
    padding: 10,
  },
  bannerHeading: {
    color: '#fff',
    fontSize: 19,
    fontWeight: 800,
    fontFamily: 'Montserrat',
  },
  banner2ndHeading: {
    color: '#fff',
    fontSize: 11,
    fontWeight: 800,
    fontFamily: 'Montserrat',
  },
  bannerSubHeading: {
    color: '#fff',
    fontSize: 12,
    fontWeight: 800,
    paddingTop: 10,
    fontFamily: 'Montserrat',
  },
  bannerText: {
    fontSize: 8,
    paddingVertical: 5,
    fontFamily: 'Montserrat',
  },
  bannerTitle: {
    color: '#4C5AFF',
    fontSize: 14,
    padding: 20,
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
  },
  bannerLabel: {
    fontSize: 10,
    color: '#fff',
    paddingTop: 5,
    fontFamily: 'Montserrat',
  },
  bannerRow: {
    flexDirection: 'row',
    paddingLeft: 20,
    alignItems: 'flex-end',
  },
  teamRow: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingTop: 20,
    paddingBottom: 10,
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bannerRowColumn: {
    flexDirection: 'column',
    padding: 20,
    paddingBottom: 0,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  teamRowColumn: {
    flexDirection: 'column',
    paddingBottom: 0,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  pageSpacer: {
    paddingVertical: 30,
  },
  pageSubHeading: {
    fontSize: 12,
    color: '#000',
    paddingBottom: 4,
    fontFamily: 'Montserrat',
  },
  pageCaption: {
    fontSize: 9,
    color: '#000',
    paddingBottom: 4,
  },
  pText: {
    fontSize: 11,
    color: '#000',
    paddingBottom: 2,
    fontFamily: 'Montserrat',
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  item: {
    width: '30%',
    marginBottom: 10,
  },
  footer: {
    position: 'absolute',
    bottom: 5,
    display: 'flex',
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20,
  },
  footerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 11,
  },
  footerLinkColumn: {
    flex: 1,
  },
  taskRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 5,
  },
  textLabel: {
    fontStyle: 'bold',
    color: 'black',
    fontSize: 12,
    fontFamily: 'Montserrat',
  },
  textValue: {
    fontStyle: 'bold',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
    paddingLeft: 5,
    fontFamily: 'Montserrat',
  },
  taskRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginTop: 5,
    borderWidth: 1,
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    borderBottom: 'none',
    minHeight: 60,
    fontFamily: 'Montserrat',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomColor: '#E0E0E0',
    borderBottomWidth: 1,
    alignItems: 'center',
    minHeight: 24,
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 14,
    paddingHorizontal: 3,
    borderLeft: 'none',
    borderRight: 'none',
    fontFamily: 'Montserrat',
  },
  tableHeaderContainer: {
    flexDirection: 'row',
    borderBottomColor: '#E0E0E0',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 20,
    textAlign: 'left',
    color: '#4C5AFF',
    fontSize: 11,
    paddingHorizontal: 3,
    borderLeft: 'none',
    borderRight: 'none',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
  },
});
