import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';

const ContentWithLabel = ({ label, content, variant }) => (
  <Box display="flex" alignItems="center" mb={2}>
    <Box width="120px">
      <FdTypography variant={variant} data-cy="label">
        {label}
      </FdTypography>
    </Box>
    {content}
  </Box>
);

ContentWithLabel.defaultProps = {
  variant: 'subtitle1',
};

ContentWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  variant: PropTypes.string,
};

export default ContentWithLabel;
