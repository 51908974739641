import { API } from 'aws-amplify';
import { createEvents } from '../../graphql/mutations';

const createEventsData = async (
  userId,
  name,
  event,
  dimension1,
  dimension2,
  dimension3,
) => {
  if (!userId || !name || !event || !dimension1) {
    return;
  }
  const now = new Date().toISOString();
  const defaultInput = {
    name,
    event,
    eventDateTime: now,
    dimension1Id: dimension1.dimension1Id,
    dimension1Name: dimension1.dimension1Name,
    userId,
  };

  await API.graphql({
    query: createEvents,
    variables: {
      input: {
        ...defaultInput,
        ...(dimension2
          ? {
              dimension2Id: dimension2.dimension2Id,
              dimension2Name: dimension2.dimension2Name,
            }
          : undefined),
        ...(dimension3
          ? {
              dimension3Id: dimension3.dimension3Id,
              dimension3Name: dimension3.dimension3Name,
            }
          : undefined),
      },
    },
  });
};

export default createEventsData;
