import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { FdProgress } from '@fifthdomain/fe-shared';
import { listOnboardingsByUserId } from '../graphql/queries';

const OptionalOnboardingRoute = ({ component: Component, userId, ...rest }) => {
  const { data: onboardingData, loading: onboardingDataLoading } = useQuery(
    gql(listOnboardingsByUserId),
    {
      variables: {
        userId,
      },
      skip: !userId,
    },
  );

  if (onboardingDataLoading) return <FdProgress />;

  const isOnboardingComplete =
    !!onboardingData?.listOnboardingsByUserId?.items.length;
  // redirect participant to survey if they haven't done.
  if (isOnboardingComplete) {
    return <Component {...rest} />;
  }

  return <Redirect to="/assessor/survey" />;
};

OptionalOnboardingRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  userId: PropTypes.string.isRequired,
};

export default OptionalOnboardingRoute;
