import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FdButton,
  FdIcons,
  FdSkeleton,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import useS3ImageLoader from '../../../hooks/useS3ImageLoader';
import useGetLogoImageKey from './hooks/useGetLogoImageKey';
import useDownloadPdf from './hooks/useDownloadPdf';
import useImageCapture from './hooks/useImageCapture';

const { GetApp: DownloadIcon } = FdIcons;
const totalImageCount = 5;

const InsightsPdfReportDownload = ({
  loading,
  userName,
  userEmail,
  assessmentName,
  ...props
}) => {
  const [chartImages, setChartImages] = useState({});
  const [instanceUpdated, setInstanceUpdated] = useState(false);
  const globalSnap = useSnapshot(globalStore);
  const { logoImageKey } = useGetLogoImageKey({
    orgId: globalSnap.orgId,
  });
  const { imageUrl: logoImageUrl } = useS3ImageLoader(logoImageKey);
  const { instance, updateInstance } = useDownloadPdf({
    userName,
    userEmail,
    assessmentName,
    chartImages,
    logoImageKey,
    logoImageUrl,
    ...props,
  });
  // fetch images from page
  useImageCapture({
    userName,
    userEmail,
    chartImages,
    setChartImages,
    instanceUpdated,
    setInstanceUpdated,
    updateInstance,
    totalImageCount,
  });

  const isLoading =
    loading ||
    Object.keys(chartImages).length < totalImageCount ||
    instance.loading;

  return (
    <FdSkeleton loading={isLoading} width="130px">
      <FdButton
        size="large"
        startIcon={<DownloadIcon />}
        href={instance.url}
        download={`${userName}-${assessmentName}.pdf`}
      >
        Reports
      </FdButton>
    </FdSkeleton>
  );
};

InsightsPdfReportDownload.propTypes = {
  userName: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  assessmentName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default InsightsPdfReportDownload;
