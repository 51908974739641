import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Image, Text, View } from '@react-pdf/renderer';
import { differenceInMinutes } from 'date-fns';
import { getCommaSeparated } from '../../../shared/utils/stringUtils';
import StarPng from '../../../resources/images/Star.png';
import NotesPng from '../../../resources/images/Notes.png';
import {
  formatMinutes,
  sortByDateField,
} from '../../../shared/utils/dateUtils';
import {
  getDifficultyLabel,
  getDifficultyLabelColor,
} from '../../../shared/utils/difficultyMapping';
import { styles } from './styles';
import PdfTable from './PdfTable';

const ProficiencyLabel = ({ label, indicator }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: 5,
    }}
  >
    <View>{indicator}</View>
    <Text
      style={{
        fontSize: 8,
        marginLeft: 5,
        fontFamily: 'Montserrat',
      }}
    >
      {label}
    </Text>
  </View>
);
ProficiencyLabel.propTypes = {
  label: PropTypes.string.isRequired,
  indicator: PropTypes.node.isRequired,
};

const EventIndicator = ({ eventText }) => {
  const color = { O: '#2196F3', S: '#8E24AA', A: '#EF5350' };
  if (eventText === 'star') {
    return (
      <View
        style={{
          display: 'flex',
          width: 15,
          height: 15,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {StarPng && <Image src={StarPng} alt="Star" />}
      </View>
    );
  }
  return (
    <View
      style={{
        display: 'flex',
        backgroundColor: color[eventText],
        width: 15,
        height: 15,
        borderRadius: 2,
        alignItems: 'center',
        justifyContent: 'center',
        borderLeft: '0.8px solid #fff',
      }}
    >
      <Text style={{ fontSize: 8, color: '#fff', fontFamily: 'Montserrat' }}>
        {eventText}
      </Text>
    </View>
  );
};
EventIndicator.propTypes = {
  eventText: PropTypes.string.isRequired,
};

const getTimeInPercentage = (_dateTime, _totalMinutes, _minDateTime) => {
  const givenTimeInMinutes = differenceInMinutes(
    new Date(_dateTime),
    new Date(_minDateTime),
  );
  return (givenTimeInMinutes / _totalMinutes) * 100;
};

const ProficiencyApproachTablePdf = ({
  taskAttempts,
  taskAttemptsDetail,
  startDateTime,
  taskNotes,
  selectedUserIds,
  tasksOpened,
  tasks,
  teamBased,
}) => {
  const allEventRows = [
    ...taskAttemptsDetail?.map((ta) => ({
      taskId: ta?.task?.id,
      event: ta?.success ? 'star' : 'A',
      dateTime: ta?.createdAt,
    })),
    ..._(tasksOpened.filter((t) => selectedUserIds.includes(t.userId)))
      .groupBy('taskOpenedTaskId')
      .map((_tasks, taskId) => ({
        taskId,
        event: 'O',
        dateTime: _.minBy(_tasks, 'createdAt').createdAt,
      }))
      .value(),
    ...tasksOpened
      .filter((to) => selectedUserIds.includes(to.userId) && to?.startedSolving)
      .map((to) => ({
        taskId: to?.taskOpenedTaskId,
        event: 'S',
        dateTime: to?.startedSolvingAt,
      })),
  ];

  const progressionData = allEventRows.sort(sortByDateField('dateTime'));
  const maxDateTime = _.maxBy(
    progressionData,
    (d) => new Date(d.dateTime),
  )?.dateTime;
  const minDateTime = startDateTime;
  const totalMinutes =
    differenceInMinutes(new Date(maxDateTime), new Date(minDateTime)) + 5;

  const timeLinePoints = Math.round(totalMinutes / 20);
  const progressionDataForGraph = progressionData?.map((pd) => ({
    ...pd,
    position: getTimeInPercentage(pd.dateTime, totalMinutes, minDateTime),
    givenTime: differenceInMinutes(
      new Date(pd.dateTime),
      new Date(minDateTime),
    ),
  }));

  const rows = _([
    ...taskAttempts,
    ...tasksOpened.map((to) => ({ ...to, taskId: to.taskOpenedTaskId })),
  ])
    .groupBy('taskId')
    .map((items, taskId) => {
      const { task } = items[0] ?? {};
      const assessmentTask = tasks?.items.find((t) => t.task?.id === taskId);
      const techniques = [
        ...new Set(
          assessmentTask?.task?.skills.items
            ?.map((skillItem) => skillItem.techniqueTags.items)
            .flat()
            .map((tagItem) => tagItem.techniqueTag.name),
        ),
      ].sort();
      const technologies = [
        ...new Set(
          assessmentTask?.task?.technologyTags.items.map(
            (tagItem) => tagItem.technologyTag.name,
          ),
        ),
      ].sort();

      return {
        ...task,
        id: taskId,
        taskName: task?.name,
        difficulty: task?.difficulty,
        skills: task?.skills?.items.map(({ skill }) => skill?.name),
        progression: progressionDataForGraph
          .filter((pg) => pg.taskId === taskId)
          .map((pgd) => ({
            position: pgd.position,
            event: pgd.event,
            givenTime:
              pgd.givenTime === 0
                ? '0m'
                : formatMinutes(pgd.givenTime, true, true),
          })),
        skillsFormatted: task?.skills?.items.map(({ skill }) => ({
          name: skill?.name,
          alias: skill?.alias,
        })),
        notes: taskNotes?.filter((tn) => tn.taskId === taskId)[0]?.notes || '', // show first note
        attemptsCount:
          taskAttemptsDetail?.filter((ta) => ta.task?.id === taskId)?.length ||
          0,
        techniques,
        technologies,
      };
    })
    .value();

  const columns = [
    {
      name: 'specialty',
      label: 'Specialty',
      width: '40%',
      textAlign: 'left',
    },
    {
      name: 'proficiency',
      label: 'Proficiency',
      width: '20%',
      textAlign: 'left',
    },
    {
      name: 'status',
      label: 'Status',
      width: '20%',
      textAlign: 'middle',
    },
    {
      name: 'attempts',
      label: 'Attempts',
      width: '20%',
      textAlign: 'center',
    },
  ];

  // set timeline legend
  let minutesInterval = 0;
  const timeLine = [];
  const timeLineSplit = Math.round(100 / timeLinePoints);
  const timeLineLimit = timeLinePoints === 0 ? 1 : timeLinePoints; // show at least one point
  for (let i = 0; i < timeLineLimit; i += 1) {
    timeLine.push({
      value: i === 0 ? '0m' : formatMinutes(minutesInterval, true, true),
      position: timeLineSplit * i,
    });
    minutesInterval += 20;
  }
  const eventMap = {
    O: 'Opened',
    S: 'Started',
    A: 'Attempted',
    star: 'Solved',
  };

  return (
    <View>
      <View style={{ marginTop: 10, marginBottom: 20 }}>
        <View
          wrap={false}
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 10,
            alignItems: 'center',
          }}
        >
          <View style={{ width: '30%' }}>
            <Text style={{ fontSize: 12, fontFamily: 'Montserrat' }}>
              Challenge Name
            </Text>
          </View>
          <View style={{ width: '70%' }}>
            <View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    marginRight: 5,
                    fontFamily: 'Montserrat',
                  }}
                >
                  Progression
                </Text>
                <View
                  style={{
                    border: '1px solid grey',
                    borderRadius: 2,
                    padding: 5,
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <ProficiencyLabel
                      label="Open"
                      indicator={<EventIndicator eventText="O" />}
                    />
                    <ProficiencyLabel
                      label="Start"
                      indicator={<EventIndicator eventText="S" />}
                    />
                    <ProficiencyLabel
                      label="Attempt"
                      indicator={<EventIndicator eventText="A" />}
                    />
                    <ProficiencyLabel
                      label="Solved"
                      indicator={<EventIndicator eventText="star" />}
                    />
                  </View>
                </View>
              </View>
              <View style={{ marginTop: 5 }}>
                {timeLine.map((tl) => (
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      top: 0,
                      position: 'absolute',
                      left: Number.isNaN(tl.position) ? 0 : `${tl.position}%`,
                    }}
                  >
                    <Text style={{ fontSize: 8, fontFamily: 'Montserrat' }}>
                      {tl.value}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>
        {rows?.map((r) => (
          <View
            wrap={false}
            style={{ marginBottom: 5, display: 'flex', flexDirection: 'row' }}
          >
            <View style={{ width: '30%' }}>
              <Text style={{ fontSize: 11 }}>{r?.taskName}</Text>
              <View
                style={{
                  backgroundColor: getDifficultyLabelColor(r?.difficulty),
                  marginTop: 5,
                  marginBottom: 5,
                  borderRadius: 2,
                  width: getDifficultyLabel(r?.difficulty)?.length * 6,
                  textAlign: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    fontFamily: 'Montserrat',
                  }}
                >
                  {getDifficultyLabel(r?.difficulty)}
                </Text>
              </View>
            </View>
            <View
              style={{
                width: '70%',
                borderBottom: '1px solid grey',
              }}
            >
              {r?.progression?.map((p) => (
                <View
                  style={{
                    top: 8,
                    left: Number.isNaN(p.position) ? 0 : `${p.position}%`,
                    position: 'absolute',
                  }}
                >
                  <EventIndicator eventText={p.event} />
                </View>
              ))}
            </View>
          </View>
        ))}
      </View>
      <View>
        {rows?.map((r, idx) => (
          <View style={{ marginTop: 30 }}>
            <View wrap={false}>
              <View style={{ marginBottom: 2 }}>
                <Text
                  style={{
                    fontSize: 14,
                    marginBottom: 5,
                    fontFamily: 'Montserrat',
                  }}
                >
                  {r.taskName}
                </Text>
                <View style={styles.horizontalLine} />
              </View>
              <PdfTable
                columns={columns}
                rows={[
                  {
                    id: idx,
                    specialty: r.specialty?.name,
                    proficiency: getDifficultyLabel(r?.difficulty),
                    attempts:
                      r.progression?.filter((p) =>
                        ['A', 'star'].includes(p.event),
                      ).length || '-',
                    status:
                      eventMap[
                        _.chain(r.progression)
                          .orderBy((item) =>
                            _.indexOf(['O', 'S', 'A', 'star'], item.event),
                          )
                          .last()
                          .value()?.event
                      ] || '',
                  },
                ]}
                showHeader
              />
            </View>
            <View>
              <View>
                <Text style={styles.bodyText}>{r?.summary}</Text>
              </View>
              <View wrap={false}>
                <Text style={styles.blockHeading}>Skills Included</Text>
                <Text style={styles.blockSubHeading}>
                  {getCommaSeparated(
                    r.skillsFormatted?.map(
                      (sf) => `${sf.name} (${sf.alias})`,
                    ) || [''],
                  )}
                </Text>
              </View>
              <View wrap={false}>
                <Text style={styles.blockHeading}>
                  Techniques and Technologies included
                </Text>
                <Text style={styles.blockSubHeading}>
                  {`Techniques: ${getCommaSeparated(r.techniques || [''])}`}
                </Text>
                <Text style={styles.blockSubHeading}>
                  {`Technologies: ${getCommaSeparated(r.technologies || [''])}`}
                </Text>
              </View>
            </View>
            <View wrap={false}>
              <View
                style={{
                  marginTop: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    width: 15,
                    height: 15,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: 2,
                    marginTop: 2,
                  }}
                >
                  {NotesPng && <Image src={NotesPng} alt="Notes" />}
                </View>
                <Text style={styles.blockHeading}>
                  {`${teamBased ? "Team's" : "Participant's"} Notes`}
                </Text>
              </View>
              <View>
                <Text style={styles.bodyText}>
                  {r?.notes || 'No notes provided.'}
                </Text>
              </View>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

ProficiencyApproachTablePdf.propTypes = {
  taskAttempts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  taskAttemptsDetail: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  startDateTime: PropTypes.string.isRequired,
  taskNotes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tasks: PropTypes.shape(PropTypes.arrayOf(PropTypes.shape({}))).isRequired,
  selectedUserIds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  teamBased: PropTypes.bool.isRequired,
  tasksOpened: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ProficiencyApproachTablePdf;
