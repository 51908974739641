import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@material-ui/core';
import { FdTooltip, FdTypography } from '@fifthdomain/fe-shared';

const ColumnHeaderWithTooltip = ({ title, subTitle }) => {
  const theme = useTheme();
  return (
    <FdTooltip
      title={
        <Box>
          <FdTypography variant="body1">{title}</FdTypography>
          {subTitle && (
            <FdTypography variant="captiontext2">{subTitle}</FdTypography>
          )}
        </Box>
      }
      arrow
    >
      <Box display="flex" flexDirection="column">
        <FdTypography variant="subtitle2">{title}</FdTypography>
        {subTitle && (
          <FdTypography
            variant="captiontext2"
            style={{ color: theme.palette.typography.secondary }}
          >
            {subTitle}
          </FdTypography>
        )}
      </Box>
    </FdTooltip>
  );
};

ColumnHeaderWithTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
};

ColumnHeaderWithTooltip.defaultProps = {
  subTitle: undefined,
};

export default ColumnHeaderWithTooltip;
