import { useEffect, useState, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSnapshot, globalStore } from '@fifthdomain/fe-shared';
import useS3ImageLoader from '../../../hooks/useS3ImageLoader';
import useImageCapture from './hooks/useImageCapture';
import useGetLogoImageKey from './hooks/useGetLogoImageKey';
import useDownloadPdf from './hooks/useDownloadPdf';

const useCallbackRef = (callback) => {
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
  return callbackRef;
};
const totalImageCount = 5;

const InsightsPdfReportGenerate = ({
  userName,
  userEmail,
  assessmentName,
  onCompleted,
  ...props
}) => {
  const [chartImages, setChartImages] = useState({});
  const [instanceUpdated, setInstanceUpdated] = useState(false);
  const globalSnap = useSnapshot(globalStore);
  const { logoImageKey } = useGetLogoImageKey({
    orgId: globalSnap.orgId,
  });
  const { imageUrl: logoImageUrl } = useS3ImageLoader(logoImageKey);
  const { instance, updateInstance } = useDownloadPdf({
    userName,
    userEmail,
    assessmentName,
    chartImages,
    logoImageKey,
    logoImageUrl,
    ...props,
  });
  const onCompletedRef = useCallbackRef(onCompleted);
  // fetch images from page
  useImageCapture({
    userName,
    userEmail,
    chartImages,
    setChartImages,
    instanceUpdated,
    setInstanceUpdated,
    updateInstance,
    totalImageCount,
  });

  // update chart after image capture
  useEffect(() => {
    if (Object.keys(chartImages).length === totalImageCount) {
      const fetchBlob = async () => {
        if (!instance.loading && instance.blob) {
          const blob = await fetch(instance.url).then((r) => r.blob());
          onCompletedRef.current?.(blob);
        }
      };
      const timer = setTimeout(() => {
        fetchBlob();
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
    return null;
  }, [
    chartImages,
    logoImageUrl,
    updateInstance,
    instance.blob,
    instance.url,
    instance.loading,
    onCompletedRef,
  ]);

  return null;
};

InsightsPdfReportGenerate.propTypes = {
  userName: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  assessmentName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onCompleted: PropTypes.func.isRequired,
};

export default InsightsPdfReportGenerate;
