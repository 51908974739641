export const upperCaseFirstLetter = (str) =>
  str && str[0].toUpperCase() + str.substring(1).toLowerCase();

export const getCommaSeparated = (arr = [], includeQuotes = false) => {
  if (arr.length === 0) {
    return '-';
  }
  const arrQ = includeQuotes ? arr.map((a) => `"${a}"`) : arr;
  if (arrQ.length === 1) return arrQ[0];
  const firsts = arrQ?.slice(0, arrQ.length - 1);
  const last = arrQ[arrQ.length - 1];
  return `${firsts.join(', ')} and ${last}`;
};

export const getSearchResults = (data, text) =>
  text === ''
    ? data
    : data?.filter((_content) =>
        Object.values(_content)
          .filter((item) => item !== null && item !== undefined && item !== '')
          .some((val) =>
            val.toString().toLowerCase().startsWith(text.toLowerCase()),
          ),
      );

// input string array
// output - "Intrusion Detection +2" or "Intrusion Detection, Firewall, VPN, +2"
export const getCommaSeparatedPlusSuffix = (strings = [], showItems = 1) => {
  if (strings.length <= showItems) {
    return strings.join(', ');
  }
  const extraValues = strings.length - showItems;
  const printedValues = strings?.slice(0, showItems);
  return `${printedValues} +${extraValues}`;
};

export const getOrdinal = (number) => {
  const suffixes = ['st', 'nd', 'rd'];
  const lastDigit = number % 10;

  // Special case: 11th, 12th, and 13th
  if (number >= 11 && number <= 13) {
    return `${number}th`;
  }

  // Otherwise, use the suffix based on the last digit
  return number + (suffixes[lastDigit - 1] || 'th');
};
